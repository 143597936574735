
import styled from "styled-components";


export const Category = styled.div`
    background-color: ${props => props.theme.color.first};
    color: white;
    padding: 3px 15px;
    margin: 5px;
    border-radius: 50px;
    display: inline-block;
`;

export const Tag = styled.div`
    background-color:${props => props.theme.color.second};
    color: white;
    padding: 3px 15px;
    margin: 5px;
    border-radius: 50px;
    display: inline-block;
`;

export default Tag;