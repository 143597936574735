import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-image: url(${props => props.img});
  background-size: cover;
  background-color: ${props => props.background};
  background-position: center center;
  margin-bottom: ${props => props.theme.spacing.m}vw;
  display: flex;
  text-align: center;
  height: auto;
  flex-wrap: wrap;
  min-height: 300px;
  margin-top: 0;

  ${breakpoint("md")`
     height: 35vw;
     margin-top: ${props => (props.flush ? `${"120px"}` : 0)};
  `}
`;

export const Image = styled.div`
  background-image: url(${props => props.img});
  background-size: contain;
  background-color: ${props => props.background};
  background-position: ${props => (props.align ? props.align : "center")} bottom;
  background-repeat: no-repeat;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  ${breakpoint("md")`
     height: 35vw;
     padding-bottom: 0;
     background-size: cover;
     width: 30%;
  `}
`;

export const Info = styled.div`
  justify-content: center !important;
  align-self: center !important;
  width: 100%;
  height: auto;
  flex-direction: column;
  display: flex;
  padding: ${props => props.theme.spacing.s}rem;
  ${breakpoint("md")`
     height: 35vw;
     width: 40%;
     padding: 0;
  `}
`;

export const Title = styled.h1`
  font-size: ${props =>
    props.small ? props.theme.font.h1.smallSize : props.theme.font.h1.size}rem;
  margin-bottom: ${props => props.theme.spacing.s}rem;
  text-align: ${props => (props.left ? "left" : "center")};
  color: white;
`;

export const Category = styled.h4`
  font-size: ${props =>
    props.small ? props.theme.font.h4.smallSize : props.theme.font.h4.size}rem;
  margin-bottom: ${props => props.theme.spacing.s}rem;
  text-align: ${props => (props.left ? "left" : "center")};
  color: white;
`;

export const Paragraph = styled.p`
  font-size: ${props =>
    props.small ? props.theme.font.p.smallSize : props.theme.font.p.size}rem;
  font-family: ${props => props.theme.font.p.family};
  margin-bottom: ${props => props.theme.spacing.s}rem;
  text-align: ${props => (props.left ? "left" : "center")};
  color: white;
`;
