import React from "react";
import classnames from "classnames";
import Symbol from "./Tools/symbol";
import Select from "./Tools/select";
import Clear from "./Tools/clear";
import styled from "styled-components";
import { compose, withHandlers, withState } from "recompose";

import "./Icons/style.css";

const toolArray = [
  { name: "Select", type: "tool" },
  { name: "Clear", type: "tool" }
  // { name: "Symbol", type: "tool" }
];

const toolMap = {
  Select: { tool: Select, drawer: null },
  // Symbol: { tool: Symbol, drawer: null },
  Clear: { tool: Clear, drawer: null }
};

const withTools = compose(
  withState("tab", "setTab", 1),
  withState("tools", "setTools", toolArray),
  withState("active", "setActive", "Select"),
  withHandlers({
    getToolComponent: ({ setActive, active }) => (item, key) => {
      let ToolItem = toolMap[item.name].tool;
      return (
        <ToolItem
          style={{ color: "white" }}
          className={classnames("tool", { active })}
          onClick={() => setActive(item.name)}
        />
      );
    }
  })
);

const FileInput = ({loadSVG}) => {
  return (
    <div class="file-input-wrapper">
      <button class="btn-file-input">Upload File</button>
      <input
        accept=".svg"
        type="file"
        name="file"
        onChange={e => loadSVG(e.target.files[0])}
      />
    </div>
  );
};

const ToolBar = ({ tools, getToolComponent, loadSVG }) => (
  <ToolBarComponent>
    {tools.map((item, key) => getToolComponent(item, key))}
    <FileInput loadSVG={loadSVG}/>
  </ToolBarComponent>
);

export default withTools(ToolBar);

const ToolBarComponent = styled.div`
  border-radius: 10px;
  overflow: hidden;
  top: 6px;
  left: 10px;
  position: relative;
  width: 50%;
  background-color: ${props => props.theme.color.first};
  flex-direction: row;
  display: flex;

  .tool{
    font-family: ${props => props.theme.font.p.family};
    font-size: ${props => props.theme.font.p.size};
    text-align: center;
    border-style: solid;
    border-width: 0px;
    border-right-width: 2px;
    border-color: white;
    justify-content: center;
    align-items: center;
    align-self: center;
    width:70px;
    height:70px;
    display: flex;
    color: white;
    &.active{
      background-color: ${props => props.theme.color.second};
    }

`;
