import React from "react";
import Avatar from "react-avatar";
import {Storage} from "aws-amplify";


class S3ImageUpload extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: ''
        }
        this.pressButton = this.pressButton.bind(this);
        this.getPhoto = this.getPhoto.bind(this);

    }

    pressButton(e) {
        e.preventDefault();
        console.log('handle uploading-', this.state.file);
    }

    getPhoto(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
        this.setState({
            file: file,
            imagePreviewUrl: reader.result
        });
        }

        reader.readAsDataURL(file);
      
    }

    render() {
      let {imagePreviewUrl} = this.state;
      return (
        <label htmlFor="myfile">
          <input style={{display: "none"}} id="myfile" type='file'  onChange={this.getPhoto}/>
          <div className="imgPreview">
            <Avatar round size={160}  src={imagePreviewUrl ? imagePreviewUrl : `http://joto.imgix.net/public/${this.props.image}`}/>
          </div>
         </label>
      )
    }
  }

  
export default S3ImageUpload;