export const USER_SETTINGS_REQUEST = "app/userSettings/USER_SETTINGS_REQUEST";
export const USER_SETTINGS_SUCCESS = "app/userSettings/USER_SETTINGS_SUCCESS";
export const USER_SETTINGS_ERROR = "app/userSettings/USER_SETTINGS_ERROR";


export default {
  USER_SETTINGS_REQUEST,
  USER_SETTINGS_SUCCESS,
  USER_SETTINGS_ERROR
};
