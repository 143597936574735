import React from "react";
import { Container, Row, Col } from "reactstrap";

import logo1 from "../../assets/images/logo1.png";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import logo4 from "../../assets/images/logo4.png";
import logo5 from "../../assets/images/logo5.png";
import logo6 from "../../assets/images/logo6.png";

const LogoBar = () => {
  return (
    <Container fluid>
    <Row>
      <Col xs="4" sm="2">
        <img src={logo1} />
      </Col>
      <Col xs="4" sm="2">
        <img src={logo2} />
      </Col>
      <Col xs="4" sm="2">
        <img src={logo3} />
      </Col>
      <Col xs="4" sm="2">
        <img src={logo4} />
      </Col>
      <Col xs="4" sm="2">
        <img src={logo5} />
      </Col>
      <Col xs="4" sm="2">
        <img src={logo6} />
      </Col>
    </Row>
    </Container>
  );
};

export default LogoBar;