import { createSelector } from 'reselect';

const PING_SLOW = 500;

const connectionSelector = state => state.connection.connection;
const onlineSelector = state => state.connection.online;
const pingSelector = state => state.connection.ping;

const connectionStatus = createSelector([connectionSelector, onlineSelector, pingSelector], (connection, online, ping) => {
  switch(true){
    // case ping > PING_SLOW:
    //   return "slow"
    // case online:
    //   return "connected"
    case online:
      return "online"
    case !online:
      return "offline"
  }

});

const connectionAlert = createSelector([connectionSelector, onlineSelector, pingSelector], (connection, online, ping) => {
  return (ping > PING_SLOW || ping === false)
});

export {
  connectionStatus,
  connectionAlert
};