import React from "react";
import PrismicReact from "prismic-reactjs";
import { Card, CardImg, CardText, CardBody, Button } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import * as CardContent from "../Styled/Card";

const CardComponent = ({
  link,
  url,
  title,
  type,
  text,
  button,
  small,
  center,
  jot,
  outerWidth,
  children,
  prismic,
  history,
  create
}) => {
  const timer = Math.floor(Math.random() * 100) + 0;
  text = prismic && text ? PrismicReact.RichText.asText(text) : text;
  title = prismic && title ? PrismicReact.RichText.asText(title) : title;
  type = prismic && type ? PrismicReact.RichText.asText(type) : type;
  return (
    <a href={link}>
      <CardContent.Container
        jot={jot}
        create={create}
        outerWidth={outerWidth}
        data-aos-once={true}
        data-aos-offset={timer}
        data-aos="slide-up"
        data-aos-easing="ease-out"
        data-aos-duration="200"
        small={small}
        link={link ? true : false}
      >
        {!children && <CardContent.Image jot={jot} img={url} />}
        <CardContent.Body center={center} small={small}>
          {!children && type && (
            <CardContent.Category small={small} center={center}>
              {type}
            </CardContent.Category>
          )}
          {!children && title && (
            <CardContent.Title small={small} center={center}>
              {title}
            </CardContent.Title>
          )}
          {!children && text && (
            <CardContent.Text small={small} center={center}>
              {text}
            </CardContent.Text>
          )}
          {!children && button && <Button>{button}</Button>}
          {children && children}
        </CardContent.Body>
      </CardContent.Container>
    </a>
  );
};

export default withRouter(CardComponent);
