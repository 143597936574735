//PRISMIC
export const CONTENT_REQUEST = "app/content/CONTENT_REQUEST";
export const CONTENT_SUCCESS = "app/content/CONTENT_SUCCESS";
export const CONTENT_ERROR = "app/content/CONTENT_ERROR";


export default {
  CONTENT_REQUEST,
  CONTENT_SUCCESS,
  CONTENT_ERROR
};