import React from "react";
import { Container, Row, Col } from "reactstrap";
import Quote from '../Styled/Quote';
import { Title, SubTitle } from "../Styled/Type";

const QuoteComponent = ({ quote, author }) => (
  <Quote>
    <Container fluid className={"shop quote"}>
      <Row>
        <Container>
          <Row>
            <Col>
              <Title>{quote}</Title>
              <SubTitle>{author}</SubTitle>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  </Quote>
);

export default QuoteComponent;
