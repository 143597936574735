import types from './types';

//////////////////////////////////////////////
//GET ALL JOTS
////////////////////////////////////////

export const searchRequest = ({ limit = 50, searchTerm = '', offset = 0 }) => ({
  type: types.SEARCH_REQUEST,
  payload: {
    limit,
    searchTerm,
    offset
  }
});

export const searchSuccess = response => ({
  type: types.SEARCH_SUCCESS,
  payload: {
    jots: response
  }
});

export const searchError = err => ({
  type: types.SEARCH_ERROR,
  payload: {
    err
  }
});

export const searchRefresh = () => ({
  type: types.REFRESH_JOT_PAGE
});
