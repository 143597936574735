//connectionActions
export const START_CONNECTION_CHECK = "app/connection/START_CONNECTION_CHECK";
export const STOP_CONNECTION_CHECK = "app/connection/STOP_CONNECTION_CHECK";
export const CONNECTION_CHANGE = "app/connection/CONNECTION_CHANGE";
export const CONNECTED = "app/connection/CONNECTED";
export const DISCONNECTED = "app/connection/DISCONNECTED";
export const PING = "app/connection/PING";
export const SLOW = "app/connection/SLOW";
export const PING_ERROR = "app/connection/PING_ERROR";
export const ONLINE = "app/connection/ONLINE";
export const OFFLINE = "app/connection/OFFLINE";
export const BACKGROUND = "app/connection/BACKGROUND";
export const FOREGROUND = "app/connection/FOREGROUND";

export default {
  START_CONNECTION_CHECK,
  STOP_CONNECTION_CHECK,
  CONNECTION_CHANGE,
  CONNECTED,
  DISCONNECTED,
  PING,
  SLOW,
  PING_ERROR,
  ONLINE,
  OFFLINE,
  BACKGROUND,
  FOREGROUND
};