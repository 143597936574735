import React from "react";
import { Col } from "reactstrap";
import Card from '../Shopify/ProductCard';

const ProductConsumable = ({product, selectedVariantQuantity, selectedVariant, selectedVariantImage, addVariantToCart}) => {
  let {title, productType, descriptionHtml, images = [], variants = []} = product;
  let variantImage = selectedVariantImage || images[0];
  let variant = selectedVariant || variants[0];
  let variantQuantity= selectedVariantQuantity || 1;
  let {id} = variant;
  return(
    <Col sm={6}  md={4} style={{ marginBottom: "2rem" }}>
      <Card title={title} variantImage={variantImage} text={descriptionHtml} price={false} type={productType} images={images} addVariantToCart={addVariantToCart} id={id} variantQuantity={variantQuantity}/>
    </Col>
  )
}


export default ProductConsumable;