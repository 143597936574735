// In src/Page.js

import React from 'react';
import PrismicReact from 'prismic-reactjs';

//Components
import Homepage from '../Screens/Homepage'
import Slices from '../Containers/Slices'
import NotFound from '../Screens/NotFound';
import NavBar from '../Containers/Navbar';

// Declare your component
export default class Page extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid

      return props.prismicCtx.api.getSingle('homepage', (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      return (
        <div data-wio-id={this.state.doc.id} style={{height: "100%"}}>
          <NavBar />
          <Homepage className={this.state.doc.type} doc={this.state.doc} {...this.props}/>
          <Slices client={this.props.client} className={this.state.doc.type} doc={this.state.doc} {...this.props}/>   
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <h1>Loading</h1>;
  }
}