import styled from "styled-components";
import { darken } from "polished";


export const Heading = styled.h1`
  ${props =>
    props.theme.setFontWithRhythm(
      props.theme.font.h1.family,
      props.small ? props.theme.font.h1.smallSize : props.theme.font.h1.size,
      props.theme.font.h1.lineHeight
    )}
`;

export const Title = styled.h2`
  ${props =>
    props.theme.setFontWithRhythm(
      props.theme.font.h2.family,
      props.small ? props.theme.font.h2.smallSize : props.theme.font.h2.size,
      props.theme.font.h2.lineHeight
    )}
`;

export const SubTitle = styled.h4`
    ${props =>
      props.theme.setFontWithRhythm(
        props.theme.font.h4.family,
        props.small ? props.theme.font.h4.smallSize : props.theme.font.h4.size,
        props.theme.font.h4.lineHeight
      )}
    color: ${props => props.theme.color.first};
    margin: 0;
`;

export const BodyText = styled.p``;

export const Link = styled.a`
    display: inline-block;
    ${props =>
      props.theme.setFontWithRhythm(
        "AvenirLTLight",
        props.small ? 0.8 : 0.9,
        1.5
      )}
    margin-top: ${props => (props.small ? 0 : props.theme.rhythmSizing(3))}rem;
    padding: 0;
    color: ${props => props.theme.color.first} !important;
    font-family: ${props => props.theme.font.h4.family};
    cursor: pointer;
    margin-bottom: 0px;
    &:last-of-type{
        margin-bottom: ${props => props.theme.spacing.m}px;
    }

    &:hover,
    &:focus {
        color: ${props => darken(0.3, props.theme.color.first)} !important;
    }
`;

export const SectionTitle = styled.div`
  text-align: center;
  font-size: ${props => props.theme.font.h1.size};
  font-family: ${props => props.theme.font.h1.family};
  line-height: ${props => props.theme.font.h1.lineHeight};
  line-height: ${props => props.theme.font.h1.lineHeight};
  margin-bottom: ${props => props.theme.spacing.l}px;
`;
