//authActions
export const OPEN_CART = "app/cart/OPEN_CART";
export const CLOSE_CART = "app/cart/CLOSE_CART";

export const GET_SHOP = "app/cart/GET_SHOP";
export const GET_PRODUCTS = "app/cart/GET_PRODUCTS";
export const GET_CART = "app/cart/GET_CART";

export const GET_CHECKOUT = "app/cart/GET_CHECKOUT";
export const SET_CHECKOUT = "app/cart/SET_CHECKOUT";

export const SET_SHOP = "app/cart/SET_SHOP";
export const SET_PRODUCTS = "app/cart/SET_PRODUCTS";
export const SET_CART = "app/cart/SET_CART";

export const UPDATE_TOTAL = "app/cart/UPDATE_TOTAL";

// withState("cartLoading", "setCartLoading", false),
// withState("productLoading", "setProductLoading", false),
// withState("products", "setProducts", []),
// withState("shop", "setShop", {}),
// withState("isCartOpen", "setIsCartOpen", false),



export default {
  GET_CHECKOUT,
  SET_CHECKOUT, 
  OPEN_CART,
  CLOSE_CART,
  GET_SHOP,
  GET_PRODUCTS,
  GET_CART,
  SET_SHOP,
  SET_PRODUCTS,
  SET_CART,
  UPDATE_TOTAL
};