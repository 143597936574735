export const GET_DEVICE_SETTINGS = "settings/GET_DEVICE_SETTINGS";
export const GET_DEVICE_SETTINGS_SUCCESS = "settings/GET_DEVICE_SETTINGS_SUCCESS";
export const GET_DEVICE_SETTINGS_ERROR = "settings/GET_DEVICE_SETTINGS_ERROR";
export const SET_DEVICE_SETTINGS = "settings/SET_DEVICE_SETTINGS";
export const SAVE_DEVICE_SETTINGS = "settings/SAVE_DEVICE_SETTINGS";
export const DEVICE_SETTINGS_SUBSCRIBE = "settings/DEVICE_SETTINGS_SUBSCRIBE";


export default {
  DEVICE_SETTINGS_SUBSCRIBE,
  GET_DEVICE_SETTINGS,
  GET_DEVICE_SETTINGS_SUCCESS,
  GET_DEVICE_SETTINGS_ERROR,
  SET_DEVICE_SETTINGS,
  SAVE_DEVICE_SETTINGS
};
