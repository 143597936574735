import React from "react";
import { Container } from "reactstrap";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import UserProfile from "../Containers/UserProfile";
import UserJots from "../Containers/UserJots";



// const AddJot = styled.div`
//   justify-content: center;
//   align-self: center;
//   position: relative;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid rgba(0, 0, 0, 0.05);
//   border-radius: 0.5rem;
//   width: 100%;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   height: 100%;
//   overflow: hidden;
// `;

const ProfilePage = ({
  banner,
  posts,
  cartTotal,
  prismicCtx,
  openCart,
  ...rest
}) => {
  return (
    <BodyWrapper>
      <Container>
        <UserProfile />
      </Container>
      <Container>
        <UserJots />
      </Container>
    </BodyWrapper>
  );
};

export default ProfilePage;
