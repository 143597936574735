import * as actions from './actions';
import types from './types';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, mergeMap, catchError, flatMap, defer, filter, tap, debounceTime } from 'rxjs/operators';
import { apiSearchJots } from '../../Api';

export const searchEpic = action$ =>
  action$.pipe(
    ofType(types.SEARCH_REQUEST),
    mergeMap(action => {
      console.log("PAYLOAD", action.payload);
      return from(apiSearchJots(action.payload)).pipe(
        flatMap(response => {
          return of(actions.searchSuccess(response));
          // return(
          //   response ? actions.searchSuccess(response)] :
          // // [ actions.searchRefresh(), actions.searchSuccess(response) ]
        }),
        catchError(error =>
          of({
            type: types.SEARCH_ERROR,
            payload: error,
            error: true
          })
        )
      );
    }),
    debounceTime(1000)
  );

export default searchEpic;
