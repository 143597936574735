import React, { Component } from 'react';
import {
  Row,
  Col,
  Container,
  Carousel,
  CarouselItem,
} from 'reactstrap';
import PrismicReact from 'prismic-reactjs';
import Video from './Video';
import {Title, SubTitle, BodyText} from '../Styled/Type';

// import './Carousel.scss';


const Pointer = ({width}) => {

  
  let left_width = `${width/window.innerWidth*100}%`;
  let right_width = `${100 - (width/window.innerWidth*100)}%`;
  
  return(
    <React.Fragment>
       <div className="pointer before" style={{width: left_width}}></div>
       <div className="pointer after" style={{width: right_width}}></div>
    </React.Fragment>
  )
}


let slides, thumbnails

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, width: 25};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.calculatePointer = this.calculatePointer.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount(){
    this.calculatePointer(0)
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === slides.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? slides.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  calculatePointer(index){
    let domRect = this['slide_' + index].getBoundingClientRect();
    let { x, y, width } = domRect;
    let xposition = x + width/2;
    this.setState({width: xposition});
  }

  goToIndex(newIndex) {
    this.calculatePointer(newIndex)
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    slides = this.props.slice.items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.icon1.url}
        >
            <div style={{height: 450}}>
              {/* <img className="img-fluid" src={item.icon1.url} alt={item.icon1.alt} /> */}
              <Video url={item.video1.url}/>
            </div>
        </CarouselItem>
      );
    });

    thumbnails = this.props.slice.items.map((item, key) => {
      return (
        <Col sm={3} key={key}>
          <div className="feature_caption" ref={(slide) => { this['slide_' + key] = slide }} onClick={() => this.goToIndex(key)}>
            <img className="feature-icon img-fluid" src={item.icon1.url} />
            <Title small>{PrismicReact.RichText.asText(item.feature_title)}</Title>
            <BodyText>{PrismicReact.RichText.asText(item.description)}</BodyText>
          </div>
        </Col>
      );
    });

    return (
      <>
         <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
            slide={false}
          >
            {slides}
          </Carousel>
          <div className="pointer_row">
            <Container>
              <Row>
                {thumbnails}
              </Row>
            </Container>
            <Pointer width={this.state.width}/>
          </div>
      </>

    );
  }
}


export default Example;







// const CarouselCompoent = ({items, activeIndex, next, previous, Pointer}) => (
//   <div>
//     <Carousel
//       activeIndex={activeIndex}
//       next={next}
//       previous={previous}
//       slide={false}
//     >
//       <Slides/>
//     </Carousel>
//     <div className="pointer_row">
//       <Container>
//         <Row>
//           <Thumbnails items={items}/>
//         </Row>
//       </Container>
//       { Pointer }
//     </div>
//   </div>

// )


// const Pointer = ({ width }) => <Pointer width={width}/>


// const Thumbnails = ({ items, goToIndex }) => (
//   items.map((item, key) => {
//     return (
//       <Col sm={3} key={key}>
//         <div className="feature_caption" onClick={() => goToIndex(key)}>
//           <img className="feature-icon img-fluid" src={item.icon1.url} />
//           <h1>{ item.feature_title }</h1>
//           <p>{ item.description }</p>
//         </div>
//       </Col>
//     )
//   })
// )


// const Slides = (items, onExiting, onExited) => (
//   items.map((item) => (
//     <CarouselItem
//       onExiting={onExiting}
//       onExited={onExited}
//       key={item.icon1.url}
//     >
//       <div style={{height: 400}}>
//         {/* <img className="img-fluid" src={item.icon1.url} alt={item.icon1.alt} /> */}
//         <Video url={item.video1.url}/>
//       </div>
//     </CarouselItem>
//     )
//   )
// )





// const withIndexState = withState('activeIndex', 'setActiveIndex', 0);

// const withPointer = compose(
//   withState('width', 'setWidth', 25),
//   lifecycle({
//     componentDidMount(){
//       this.calculatePointer(0)
//     }
//   }),
//   withHandlers({
//     calculatePointer:  ({setWidth}) => (index, element) => {
//       let domRect = this['slide_' + index].getBoundingClientRect();
//       let { x, y, width } = domRect;
//       let xposition = x + width/2;
//       setWidth(xposition);
//     }
//   })
// ) 

// const withAnimatePosition = compose(
//   withState('activeIndex', 'setActiveIndex', 0),
//   withState('animating', 'setAnimating', false),
//   withHandlers({
//     onExiting: ({setAnimating}) => () => setAnimating(true),
//     onExited: ({setAnimating}) => () => setAnimating(false),
//     next: ({animating, activeIndex, setActiveIndex, slides}) => () => {
//       if (animating) return;
//       setActiveIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1)
//     },
//     previous: ({animating, activeIndex, setActiveIndex, slides}) => () => {
//       if (animating) return;
//       setActiveIndex(activeIndex === 0 ? slides.length - 1 : activeIndex - 1)
//     },
//     goToIndex: ({animating, setActiveIndex}) => newIndex => {
//       //this.calculatePointer(newIndex)
//       if (animating) return;
//       setActiveIndex(newIndex)
//       }
//   })
// )


// const enhance = compose(
//   withIndexState,
//   withPointer,
//   withAnimatePosition
// )

// export default enhance(CarouselCompoent)