import styled from "styled-components";

export const Container = styled.div`
    align-self: center;
    flex-grow: 1;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    display: flex;
`

 export const Bar = styled.div`
    flex-shrink: 0;
 `

