import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";

const TabHeader = ({ title, handleClick, activeTab }) => {
  return (
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab })}
        onClick={handleClick}
      >
        {title}
      </NavLink>
    </NavItem>
  );
};

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this._nodes = new Map();
    this.myDiv = React.createRef()
    this.toggle = this.toggle.bind(this);
    this.state = {
      height: 0,
      activeTab: 1
    };
  }

  componentDidMount() {

    let heightArray = Array.from(this._nodes.values())
    .filter(node => node != null)
    .map(node => {
      return node.clientHeight
    });
    this.setState({height: Math.max.apply(Math, heightArray)});
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
      <div>
        <Nav tabs>
          {this.props.children.map((tab, key) => {
            return (
              <TabHeader
                key={key}
                title={tab.props.title}
                activeTab={this.state.activeTab === key + 1}
                handleClick={() => this.toggle(key + 1)}
              />
            );
          })}
        </Nav>
        <TabContent activeTab={this.state.activeTab} style={{height: this.state.height}}>
          {this.props.children.map((tab, key) => {
            return (
                <TabPane
                  key={key}
                  tabId={key + 1}
                >
                  <Row>
                    <Col sm="12">
                      <div style={{overflow: "hidden"}} key={key} ref={c => this._nodes.set(key, c)}>
                        {tab}
                      </div>
                   </Col>
                  </Row>
                </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }
}




// .tab-pane{
//   position: absolute;
// }

// .tab-content{
//   margin: $spacing-xs 0;
// }

// .tab-content>.tab-pane{
//   display:block;
//   visibility:hidden;
// }

// .tab-content>.active{
//   visibility:visible;
// }