import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrismicApp from './PrismicApp';
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { ThemeProvider} from 'styled-components';
import {theme, rhythm} from './styles/theme';


const tagManagerArgs = {
    gtmId: 'GTM-5KHRBD'
}
 
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

ReactPixel.init('1651082681634015', null, options);

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <ThemeProvider theme={Object.assign({}, theme, rhythm.theme)}>
      <PrismicApp />
  </ThemeProvider>,
  document.getElementById('root'),
);

  if (module.hot) {
  module.hot.accept();
}