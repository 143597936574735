import types from './types';

//LOGIN ACTIONS
export const loginRequest = () => ({
  type: types.LOGIN_REQUEST
});

export const loginFurther = user => ({
  type: types.LOGIN_FURTHER,
  user
});

export const loginSuccess = user => {
  return {
    type: types.LOGIN_SUCCESS,
    user
  };
};

export const loginError = err => ({
  type: types.LOGIN_ERROR,
  err
});

//LOGOUT ACTIONS
export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const logoutError = err => ({
  type: types.LOGOUT_ERROR,
  err
});

//REGISTER ACTIONS
export const registerRequest = () => ({
  type: types.REGISTER_REQUEST
});

export const registerSuccess = email => ({
  type: types.REGISTER_SUCCESS,
  email
});

export const registerError = err => ({
  type: types.REGISTER_ERROR,
  err
});

//VERIFY ACTIONS
export const verifyRequest = () => ({
  type: types.VERIFY_REQUEST
});

export const verifySuccess = () => ({
  type: types.VERIFY_SUCCESS
});

export const verifyError = err => ({
  type: types.VERIFY_ERROR,
  err
});

//VERIFY ACTIONS
export const resendVerifyRequest = () => ({
  type: types.RESEND_VERIFY_REQUEST
});

export const resendVerifySuccess = () => ({
  type: types.RESEND_VERIFY_SUCCESS
});

export const resendVerifyError = err => ({
  type: types.RESEND_VERIFY_ERROR,
  err
});

//CHANGE PASSWORD
export const passwordChangeRequest = () => ({
  type: types.PASSWORD_CHANGE_REQUEST
});

export const passwordChangeSuccess = () => ({
  type: types.PASSWORD_CHANGE_SUCCESS
});

export const passwordChangeError = err => ({
  type: types.PASSWORD_CHANGE_ERROR,
  err
});

//FORGOT PASSWORD
export const passwordForgotRequest = login => ({
  type: types.PASSWORD_FORGOT_REQUEST,
  login
});

export const passwordForgotSuccess = () => ({
  type: types.PASSWORD_FORGOT_SUCCESS
});

export const passwordForgotError = err => ({
  type: types.PASSWORD_FORGOT_ERROR,
  err
});

export const passwordResetRequest = () => ({
  type: types.PASSWORD_RESET_REQUEST
});

export const passwordResetSuccess = () => ({
  type: types.PASSWORD_RESET_SUCCESS
});

export const passwordResetError = err => ({
  type: types.PASSWORD_RESET_ERROR,
  err
});

//3) ADMIN CREATED USER RESET
export const passwordForceResetRequest = () => ({
  type: types.PASSWORD_FORCE_RESET_REQUEST
});

export const passwordForceResetSuccess = () => ({
  type: types.PASSWORD_FORCE_RESET_SUCCESS
});

export const passwordForceResetError = err => ({
  type: types.PASSWORD_FORCE_RESET_ERROR,
  err
});
