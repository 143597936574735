import React, {Image} from 'react';

const Circles = () => (
    <div>
        <div className="circle1_container" data-aos-once={true} data-aos-delay={120} data-aos="slide-up" data-aos-easing="linear" data-aos-duration="2000">
            <div className="circle1"/>
        </div>
        <div className="circle2_container" data-aos-once={true} data-aos-delay={120} data-aos="slide-up" data-aos-easing="linear" data-aos-duration="2000">
            <div className="circle2"/>
        </div>
        <div className="circle3_container" data-aos-once={true} data-aos-delay={120} data-aos="slide-up" data-aos-easing="linear" data-aos-duration="2000">
            <div className="circle3"/>
        </div>
    </div>
   
);

export default Circles;
