//JOT REDUCER
export const JOT_GET_REQUEST = "app/create/JOT_GET_REQUEST";
export const JOT_GET_SUCCESS = "app/create/JOT_GET_SUCCESS";
export const JOT_GET_ERROR = "app/create/JOT_GET_ERROR";
export const JOT_FETCH_IMAGE = "app/create/JOT_FETCH_IMAGE";

//GET SVG FROM WEBAPP - PARSE BEFORE CREATING JOTS
export const JOT_PROCESS_REQUEST = "app/create/JOT_PROCESS_REQUEST";
export const JOT_PROCESS_SUCCESS = "app/create/JOT_PROCESS_SUCCESS";
export const JOT_PROCESS_ERROR = "app/create/JOT_PROCESS_ERROR";

//CREATE JOT
export const JOT_CREATE_REQUEST = "app/create/JOT_CREATE_REQUEST";
export const JOT_CREATE_SUCCESS = "app/create/JOT_CREATE_SUCCESS";
export const JOT_CREATE_ERROR = "app/create/JOT_CREATE_ERROR";

//CREATE JOT
export const JOT_UPLOAD_REQUEST = "app/create/JOT_UPLOAD_REQUEST";
export const JOT_UPLOAD_SUCCESS = "app/create/JOT_UPLOAD_SUCCESS";
export const JOT_UPLOAD_ERROR = "app/create/JOT_UPLOAD_ERROR";

//SEND JOT TO DEVICE
export const JOT_DRAFT_ADD = "app/create/JOT_DRAFT_ADD";
export const JOT_DRAFT_WAITING= "JOT_DRAFT_WAITING";
export const JOT_DRAFT_REMOVE = "app/create/JOT_DRAFT_REMOVE";
export const ADD_TO_JOTO = "app/create/ADD_TO_JOTO";

//SEND JOT TO DEVICE
export const JOT_SEND_REQUEST = "app/create/JOT_SEND_REQUEST";
export const JOT_SEND_SUCCESS = "app/create/JOT_SEND_SUCCESS";
export const JOT_SEND_ERROR = "app/create/JOT_SEND_ERROR";
export const JOT_SEND_CANCEL = "app/create/JOT_SEND_CANCEL";
export const JOT_SEND_WAITING = "app/create/JOT_SEND_WAITING";

//SEND JOT TO DEVICE
export const JOT_DOWNLOAD_BEGIN = "app/create/JOT_DOWNLOAD_BEGIN";
export const JOT_DOWNLOAD_END = "app/create/JOT_DOWNLOAD_END";
export const JOT_DOWNLOAD_ERROR = "app/create/JOT_DOWNLOAD_ERROR";
// export const JOT_SEND_REQUEST = "app/create/JOT_SEND_REQUEST";
export const JOT_DRAW_PROGRESS_UPDATE = "app/create/JOT_DRAW_PROGRESS_UPDATE"
// export const JOT_SEND_SUCCESS = "app/create/JOT_SEND_SUCCESS";


export default {
  JOT_GET_REQUEST,
  JOT_GET_SUCCESS,
  JOT_GET_ERROR,
  JOT_FETCH_IMAGE,
  JOT_PROCESS_REQUEST,
  JOT_PROCESS_SUCCESS,
  JOT_PROCESS_ERROR,
  JOT_CREATE_REQUEST,
  JOT_CREATE_SUCCESS,
  JOT_CREATE_ERROR,
  JOT_UPLOAD_REQUEST,
  JOT_UPLOAD_SUCCESS,
  JOT_UPLOAD_ERROR,
  JOT_DRAFT_ADD,
  JOT_DRAFT_REMOVE,
  JOT_SEND_REQUEST,
  JOT_SEND_SUCCESS,
  JOT_SEND_ERROR,
  JOT_SEND_CANCEL,
  JOT_DOWNLOAD_BEGIN,
  JOT_DOWNLOAD_END,
  JOT_SEND_REQUEST,
  JOT_DRAW_PROGRESS_UPDATE,
  ADD_TO_JOTO
}