import React, {Component} from 'react';
import VideoCover from 'react-video-cover';
import classnames from 'classnames';

class MinimalCoverExample extends Component {
  componentDidMount() {
    this.videoRef.play();
  }
  
  render() {
    const videoOptions = {
      src: this.props.url,
      loop: true,
      muted: true,
      ref: videoRef => {
        this.videoRef = videoRef;
      },
      onClick: () => {
        if (this.videoRef && this.videoRef.paused) {
          this.videoRef.play();
        } else if (this.videoRef) {
          this.videoRef.pause();
        }
      },
      title: 'click to play/pause',
    };
    return (
        <VideoCover videoOptions={videoOptions}/>
    );
  }
}

export default MinimalCoverExample