import searchReducer from './reducers';

// import * as searchSelectors from "./selectors";
import * as searchOperations from './operations';
import * as searchTypes from './types';
import searchEpics from './epics';

export { searchOperations, searchTypes, searchEpics };

export default searchReducer;
