/* LINE ITEMS
 * ============================== */
import styled from "styled-components";


 export const LineItem = styled.li`
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

export const LineItemImage = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
`;

export const LineItemContent = styled.div`
  width: 100%;
  padding-left: 75px;
`;

export const LineItemContentRow = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
`;

export const LineItemVariantTitle = styled.div`
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
`;

export const LineItemTitle = styled.span`
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
`;

export const LineItemPrice = styled.span`
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
`;

export const LineItemQuantityContainer = styled.div`
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
`;

export const LineItemQuantityUpdate = styled.button`
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
`;

export const LineItemQuantityUpdateForm = styled.div`
  display: inline;
`;

export const LineItemQuantity = styled.span`
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
`;

export const LineItemRemove = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;

  &:hover = {
    opacity: 1;
    cursor: pointer;
  }
`;
