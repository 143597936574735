import React from "react";
import Prismic from "prismic-javascript";
import PrismicReact from "prismic-reactjs";
import Avatar from "react-avatar";
import { Navbar, Nav, NavItem } from "reactstrap";
import { Container, Row, Col, CardDeck } from "reactstrap";
import { SubTitle, Link } from "../Styled/Type";
import { NavLink } from "react-router-dom";
import {
  SubNav,
  LeftNav,
  SubNavItems,
  RightProfile,
  UserProfile
} from "../Styled/SubNav";
import { cognitoLogout } from "../../State/authentication/operations";
import { connect } from "react-redux";

const mapDispatchToProps = {
  cognitoLogout
};

const mapStateToProps = state => {
  return {
    firstname: state.profile.info.firstname,
    lastname: state.profile.info.lastname,
    isLoggedIn: state.authentication.isLoggedIn
  };
};

const SubNavComponent = ({
  cognitoLogout,
  offset,
  isLoggedIn,
  firstname,
  lastname,
  expand
}) => {
  return isLoggedIn ? (
    <SubNav top={offset}>
      <Container>
        <Row>
          <SubNavItems>
            <LeftNav>
              <NavLink to="/create">Create Jot</NavLink>
              <NavLink to="/profile">Your Profile</NavLink>
            </LeftNav>
            <RightProfile>
              <UserProfile>
                <SubTitle small>{`${firstname} ${lastname}`}</SubTitle>
                <Link small onClick={cognitoLogout}>
                  Log Out
                </Link>
              </UserProfile>

              <Avatar
                round
                size={expand ? 35 : 45}
                name={`${firstname} ${lastname}`}
              />
            </RightProfile>
          </SubNavItems>
        </Row>
      </Container>
    </SubNav>
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubNavComponent);
