import React, { Component } from "react";

import { Tag, Category } from "../Styled/Tag";

const Tags = ({ tags, isCategory }) => (
  <div>
    {tags
      ? tags.map((tag, key) =>
          isCategory ? (
            <Tag key={key}>{tag}</Tag>
          ) : (
            <Category key={key}>{tag}</Category>
          )
        )
      : null}
  </div>
);

export default Tags;
