import firmwareReducer from "./reducers";

import * as firmwareOperations from "./operations";
import * as firmwareTypes from "./types";

export {
    firmwareOperations,
    firmwareTypes,
};

export default firmwareReducer;
