import { Container, Row, Col } from "reactstrap";
import PrismicReact from "prismic-reactjs";
import React from "react";
import Slices from "../../Containers/Slices.js";
import {Title, SubTitle} from "../../Components/Styled/Type";

const RichComponent = ({title,  subtitle, prismicCtx, body, ...rest }) => {
  title = title ? PrismicReact.RichText.asText(title) : null;
  subtitle = subtitle ? PrismicReact.RichText.asText(subtitle) : null;

  return (
  <Container>
    <Row>
      <Col lg={{size: 8, offset: 2}} >
      <SubTitle>{subtitle}</SubTitle>
      <Title>{title}</Title>
      <Slices body={body} prismicCtx={prismicCtx} />
      </Col>
    </Row>
  </Container>
)};

export default RichComponent;
