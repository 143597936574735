import types from './types';
import _ from 'lodash';
import { combineReducers } from 'redux';

const objFromArray = array => {
  return array.reduce((accumulator, current) => {
    accumulator[current.jotId] = current;
    return accumulator;
  }, {});
};

const idArray = array => {
  return array.map(jot => jot.jotId);
};

const pages = (pages = {}, action = {}) => {
  switch (action.type) {
    case types.REQUEST_JOT_PAGE:
      return {
        ...pages,
        [action.payload.lastEvaluatedKey.jotId]: {
          ids: [],
          fetching: true
        }
      };
    // case types.RECEIVE_JOT_PAGE:
    //   return {
    //     ...pages,
    //     [action.payload.lastEvaluatedKey.jotId]: {
    //       ids: action.payload.jots.map(jot => jot.title),
    //       fetching: false,
    //       lastEvaluatedKey: action.payload.lastEvaluatedKey
    //     }
    //   }
    default:
      return pages;
  }
};

const updateCurrentPage = (state, action) => {
  const { payload } = action;
  const currentPage = payload.lastEvaluatedKey;
  return {
    ...state,
    ...currentPage
  };
};

const currentPage = (state = null, action) => {
  switch (action.type) {
    case types.RECEIVE_JOT_PAGE:
      return updateCurrentPage(state, action);
    default:
      return state;
  }
};

// const refreshing = (state = null, action ) => {
//   switch (action.type) {
//     case types.REFRESH_JOT_PAGE:
//       return true
//     case types.RECEIVE_JOT_PAGE:
//       return false
//     default:
//       return state
//   }
// }

const pagination = combineReducers({
  //pages,
  // refreshing: refreshing,
  currentPage: currentPage
});

const getJotsById = (state, action) => {
  const { payload } = action;
  const jots = objFromArray(payload.jots);

  return {
    ...state,
    ...jots
  };
};

const addJotsById = (state, action) => {
  const { payload } = action;
  const jots = objFromArray(payload.jots);

  return {
    ...state,
    ...jots
  };
};

const jotsById = (state = {}, action) => {
  switch (action.type) {
    case types.SEARCH_SUCCESS:
      return getJotsById(state, action);
    case types.RECEIVE_ALL_jOTS:
    case types.RECEIVE_JOT_PAGE:
      return addJotsById(state, action);
    case types.REFRESH_JOT_PAGE:
      return {};
    default:
      return state;
  }
};

const loading = (state = {}, action) => {
  switch (action.type) {
    case types.REQUEST_JOT_PAGE:
      return true;
    case types.RECEIVE_JOT_PAGE:
      return false;
    default:
      return state;
  }
};

const getJotIds = (state, action) => {
  const { payload } = action;
  const jotIds = idArray(action.payload.jots);
  return _.uniq(state.concat(jotIds));
};

const addJotIds = (state, action) => {
  const { payload } = action;
  const jotIds = idArray(action.payload.jots);
  // Just append the new Comment's ID to the list of all IDs
  return _.uniq(state.concat(jotIds));
};

const allJotIds = (state = [], action) => {
  switch (action.type) {
    case types.SEARCH_SUCCESS:
      return getJotIds(state, action);
    case types.RECEIVE_ALL_jOTS:
    case types.RECEIVE_JOT_PAGE:
      return addJotIds(state, action);
    case types.REFRESH_JOT_PAGE:
      return [];
    default:
      return state;
  }
};

const jotsReducer = combineReducers({
  byId: jotsById,
  allIds: allJotIds,
  loading
});

export default combineReducers({
  jots: jotsReducer,
  pagination
});
