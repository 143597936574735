import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Title} from '../Components/Styled/Type';
import BuyButton from '../Components/Styled/BuyButton';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <div>
        <BuyButton small color="second" onClick={this.toggle}>{this.props.buttonLabel}</BuyButton>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>
            <Title small>Edit Profile</Title>
          </ModalHeader>
          <ModalBody>
            {this.props.content}
          </ModalBody>
          <ModalFooter>
            <BuyButton color="first" onClick={this.toggle}>Save</BuyButton>
            <BuyButton color="second" onClick={this.toggle}>Cancel</BuyButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;