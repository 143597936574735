import pairingReducer from "./reducers";

import * as pairingOperations from "./operations";
import * as pairingTypes from "./types";

export {
    pairingOperations,
    pairingTypes
};

export default pairingReducer;
