import React from 'react';
import Video from '../Components/Elements/Video';

export default class Page extends React.Component {

  render() {
      return (
        <div style={{height: "100%", width: "100%"}}>
          <Video className="main-video" url={"https://player.vimeo.com/external/232445914.hd.mp4?s=f627e0ee7b51ebe69ce1868f53fa0ef6e4055951&profile_id=174"}/>
        </div>
      )
  }
}