import React, { Component } from 'react';

import CreatableSelect from 'react-select/lib/Creatable';


const customStyles = {

  control: (provided, state) => ({
    ...provided,
    borderRadius: '50px',
    fontFamily: "AvenirLTLight",
    padding: ".175rem .175rem",
    fontSize: "0.75rem"
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "AvenirLTLight",
    padding: ".175rem .75rem",
    fontSize: "0.75rem"
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#D9ECFC',
      borderRadius: "50px",
      fontFamily: "AvenirLTRoman",
      fontSize: "1rem",
      padding: ".075rem .575rem",
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      backgroundColor: '#D9ECFC',
      ':hover': {
        backgroundColor: '#D9ECFC',
      },
    };
  },
}


const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export default class CreatableInputOnly extends Component {
  

  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      value: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleChange(value) {
    this.props.onChange("tags", value.map(item => item.value));
    this.setState({ value });
  };

  handleBlur = () => {
    this.props.onBlur("tags", true);
  };
  
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let newvalue = [...value, createOption(inputValue)];
        this.setState({
          inputValue: '',
          value: newvalue,
        });
        this.props.onChange("tags", newvalue.map(item => item.value));
        event.preventDefault();
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
        styles={customStyles}

      />
    );
  }
}