import React from 'react';
import PrismicReact from 'prismic-reactjs';
import {Container, Row, Col} from 'reactstrap';
import TextStandard from './TextStandard';
import ImageStandard from './ImageStandard';
import Circles from './Circles';

const Left = ({primary}) => {
  return (
    <div className="left slice">
      <Container>
        <Row>
          <Col sm={{size: 7, offset: 0}} data-aos="fade-in" data-aos-once data-aos-duration="700">
            <ImageStandard {...primary.block_image} />
          </Col>
          <Col  sm={{size: 4, offset: 0}}>
            <TextStandard {...primary}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const Right = ({primary}) => {
  return (
    <div className="right slice">
      <Circles />
      <Container>
        <Row>
          <Col xs={{order: 2}} md={{size: 4, offset: 1, order: 1}}>
            <TextStandard {...primary}/>
          </Col>
          <Col xs={{order: 1}} md={{size: 7, offset: 0, order: 2}} data-aos="fade-in" data-aos-once data-aos-duration="700">
            <ImageStandard {...primary.block_image} />
          </Col>
        </Row>
      </Container>
     
    </div>
  )
}

const Center = ({primary}) => {
  return (
    <div className="center slice">
    <Container>
      <Row>
        <Col sm={{ size:8, offset: 2}} >
            <TextStandard {...primary}/>
            <ImageStandard data-aos="fade-in" data-aos-once data-aos-duration="700" {...primary.block_image} />
        </Col>
      </Row>
      </Container>
    </div>
  )
}

const ContentBlock = (props) => {
  let {primary, items} = props.slice;
    switch(primary.block_alignment){
      case "Left":
        return <Left primary={primary} />
      break;
      case "Right":
        return <Right primary={primary} />
      break;
      case "Center":
        return <Center primary={primary} />
      break;
    }

}

export default ContentBlock;