import Amplify, { Auth, Storage } from "aws-amplify";
import { apiGetJot, apiCreateJot, apiSendJot } from "../../Api";

// TODO: this should probably be passed props and filename maybe not random
import { AmplifyConfig } from "../../config/index";
Amplify.configure(AmplifyConfig);

export const jotGet = jotId =>
  new Promise((resolve, reject) => {
    apiGetJot(jotId)
      .then(jot => resolve(jot))
      .catch(err => reject(err));
  });

  
  export const createJot = validsvg =>
  new Promise((resolve, reject) => {
    const filename = `${Math.floor(Date.now() / 1000)}.svg`;

    const params = {
      title: `${Math.floor(Date.now() / 1000)}`,
      description: 'Private Jot',
      categories: [],
      tags: [],
      svgFilename: filename
    };

    Auth.currentAuthenticatedUser()
      .then(user =>
        Storage.put(`jots/${filename}`, validsvg, {
          level: 'protected',
          contentType: 'image/svg+xml'
        })
      )
      .then(response => {
        params.svgFilename = filename;
        console.log("PARAMS", params);
        return apiCreateJot(params);
      })
      .then(jot => {
        //console.log("PROMISE JOT", jot)
        return resolve(jot);
      })
      .catch(err => reject(err));
  });

export const jotSend = (deviceId, jotId) =>
  new Promise((resolve, reject) => {
    apiSendJot(deviceId, jotId)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });
