import styled from "styled-components";

const Component = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  > .css-0 {
    height: 50px;
  }
`;

export default Component;
