import React from "react";
import { compose } from "recompose";
import { Container} from "reactstrap";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import Banner from "../Components/Elements/Banner";
import { withFetchPage } from "../Prismic/Helpers";
import Slices from "../Containers/Slices";

const enhance = compose(withFetchPage("blog_feed"));

const BlogComponent = ({ cartTotal, prismicCtx, openCart, doc }) => {
  const {
    data: {
      blog_feed_banner_title,
      blog_feed_banner_text,
      blog_banner_image_left,
      blog_banner_image_right,
      blog_feed_banner_subtitle,
      banner_colour,
      body
    } = {}
  } = doc || {};
  return doc ? (
    <BodyWrapper feed>
      <Container fluid>
        <Banner
          flush
          prismic
          background={banner_colour}
          title={blog_feed_banner_title}
          image={{
            left: blog_banner_image_left,
            right: blog_banner_image_right
          }}
          subtitle={blog_feed_banner_subtitle}
          text={blog_feed_banner_text}
        />
      </Container>
      <Container>
        <Slices body={body} prismicCtx={prismicCtx} />
      </Container>
    </BodyWrapper>
  ) : (
    <h1>Loading</h1>
  );
};

export default enhance(BlogComponent);
