import settingsReducer from "./reducers";

// import * as iotSelectors from "./selectors";
import * as settingsOperations from "./operations";
import * as settingsTypes from "./types";

export {
    settingsOperations,
    settingsTypes
};

export default settingsReducer;
