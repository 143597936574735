export const SHADOW_SUBSCRIBE = "app/shadow/SHADOW_SUBSCRIBE";
export const SHADOW_DISCONNECTED = "app/shadow/SHADOW_DISCONNECTED";
export const SHADOW_CONNECTED = "app/shadow/SHADOW_CONNECTED";
export const SHADOW_REQUEST = "app/shadow/SHADOW_REQUEST";
export const SHADOW_UPDATE = "app/shadow/SHADOW_UPDATE";
export const SHADOW_FETCH = "app/shadow/SHADOW_FETCH";
export const SHADOW_ERROR = "app/shadow/SHADOW_ERROR";

export const SHADOWS_SUBSCRIBE = "app/shadow/SHADOWS_SUBSCRIBE";

export const SHADOW_GET_JOT_REQUEST = "app/shadow/SHADOW_GET_JOT_REQUEST";
export const SHADOW_GET_JOT_SUCCESS = "app/shadow/SHADOW_GET_JOT_SUCCESS";
export const SHADOW_GET_JOT_ERROR = "app/shadow/SHADOW_GET_JOT_ERROR";

export const PING_UPDATE = "app/shadow/PING_UPDATE";
export const PING_ERROR = "app/shadow/PING_ERROR";

export const CONNECT_EVENT = "app/shadow/CONNECT_EVENT";

export default {
  SHADOW_REQUEST,
  SHADOW_SUBSCRIBE,
  SHADOW_DISCONNECTED,
  SHADOW_CONNECTED,
  SHADOW_UPDATE,
  SHADOW_FETCH,
  SHADOW_GET_JOT_REQUEST,
  SHADOW_GET_JOT_SUCCESS,
  SHADOW_GET_JOT_ERROR,
  SHADOW_ERROR,
  SHADOWS_SUBSCRIBE,
  PING_UPDATE,
  PING_ERROR,
  CONNECT_EVENT
};
