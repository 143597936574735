import axios from "axios";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { post } from "axios";

const axiosInstance = axios.create({
  baseURL: "http://192.168.4.1",
  timeout: 30000
});

export const getConfig = () =>
  axiosInstance({
    method: "post",
    url: "/rpc/Config.Get"
  }).then(response => {
    return response;
  });

export const saveConfig = ({ reboot }) =>
  axiosInstance({
    method: "post",
    url: "/rpc/Config.Save",
    data: JSON.stringify({ reboot })
  });

export const setConfig = config =>
  axiosInstance({
    method: "post",
    url: "/rpc/Config.Set",
    data: JSON.stringify(config, false)
  }).then(response => {
    return response;
  });

export const scanWifiNetworks = () =>
  axiosInstance
    .get("/rpc/Wifi.Scan")
    .then(response => Promise.resolve(_.uniqBy(response.data, "ssid")))
    .catch(err => Promise.reject(err));

export const pairDevice = async ({ network, password, reboot }) => {
  const credentials = await Auth.currentCredentials();
  const {username} = await Auth.currentAuthenticatedUser();

  return setConfig({
    config: {
      userId: {
        id: `${username}=${credentials._identityId}`
      },
      mode: {
        pairing: false
      },
      wifi: {
        sta: {
          enable: true,
          ssid: network,
          pass: password
        },
        ap: {
          enable: false
        }
      }
    }
  }).then(response => saveConfig({ reboot: true }));
};

export const downloadLatestFirmware = () =>
  axios({
    url:
      "https://s3-eu-west-1.amazonaws.com/joto-release-firmware/local/fw_1.zip",
    method: "GET",
    responseType: "blob" // important
  })
    .then(response => {
      var fr = new FileReader();
      fr.onload = e => (localStorage["icon"] = e.target.result);
      fr.readAsDataURL(response);
    })
    .catch(err => console.log(err));

export const localFirmwareUpload = ({ uri, size, setProgress }) => {
  const url = "http://192.168.4.1/update";
  const formData = new FormData();
  // formData.append("file", { uri, name: "file", type: "multipart/form-data" });
  let blob = localStorage.getItem("icon");
  formData.append("file", {
    uri: blob,
    name: "file",
    type: "multipart/form-data"
  });
  const config = {
    onUploadProgress: progressEvent => {
      setProgress(progressEvent.loaded / size);
    }
  };

  return new Promise((resolve, reject) => {
    // axios.defaults.headers.common["Content-Type"] =
    //   "multipart/form-data; boundary=someArbitraryUniqueString";
    console.log(url, formData);
    post(url, formData)
      .then(response => console.log(response))
      .catch(error => reject(error));
  });
};
