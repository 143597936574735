import React from "react";
import { Container, Row, Col } from "reactstrap";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import DiagnosticProfile from "../Containers/DiagnosticProfile";
import UserJots from "../Containers/UserJots";
import FirmwareUpdater from "../Containers/FirmwareUpdater";
import WifiSelect from "../Containers/WifiSelect";
import ConfigRPC from "../Containers/ConfigRPC";
import Pairing from "../Containers/Pairing";

// const AddJot = styled.div`
//   justify-content: center;
//   align-self: center;
//   position: relative;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid rgba(0, 0, 0, 0.05);
//   border-radius: 0.5rem;
//   width: 100%;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   height: 100%;
//   overflow: hidden;
// `;

const ProfilePage = ({
  banner,
  posts,
  cartTotal,
  prismicCtx,
  openCart,
  ...rest
}) => {
  return (
    <BodyWrapper>
      <div style={{ paddingTop: 80, width: '100%' }}>
        <Container>
          <Row>
            <Col sm={10}>
              <ConfigRPC />
              <hr />
            </Col>
            <Col sm={10}>
              <WifiSelect />
              <hr />
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <FirmwareUpdater />
              <hr />
            </Col>
            <Col sm={10}>
              <Pairing />
              <hr />
            </Col>
          </Row>
        </Container>
        <Container />
      </div>
    </BodyWrapper>
  );
};

export default ProfilePage;
