import {
  CONNECTION_REQUEST,
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,
  WIFI_REQUEST,
  WIFI_SUCCESS,
  WIFI_ERROR,
  WIFI_REFRESH,
  PAIRING_REQUEST,
  PAIRING_SUCCESS,
  PAIRING_ERROR,
  LOCAL_CONNECTION_CHECK,
  LOCAL_FIRMWARE_DOWNLOAD_ERROR,
  LOCAL_FIRMWARE_DOWNLOAD_SUCCESS,
  LOCAL_FIRMWARE_UPDATE_REQUEST,
  LOCAL_FIRMWARE_UPDATE_ERROR,
  LOCAL_FIRMWARE_UPDATE_SUCCESS,
  SET_WIFI_PASSWORD,
  SET_WIFI_NETWORK
} from './types';

export const connectionRequest = () => ({
  type: CONNECTION_REQUEST
});

export const connectionSuccess = () => ({
  type: CONNECTION_SUCCESS
});

export const connectionError = () => ({
  type: CONNECTION_ERROR
});

export const networkScanRequest = () => ({
  type: WIFI_REQUEST
});

export const networkScanRefresh = () => ({
  type: WIFI_REFRESH
});

export const networkScanSuccess = networks => ({
  type: WIFI_SUCCESS,
  networks
});

export const networkScanError = () => ({
  type: WIFI_ERROR
});

export const setWifiPassword = password => ({
  type: SET_WIFI_PASSWORD,
  password
});

export const setWifiNetwork = network => ({
  type: SET_WIFI_NETWORK,
  network
});

export const pairingRequest = ({ network, password, username, reboot }) => ({
  type: PAIRING_REQUEST,
  network,
  password,
  username,
  reboot
});

export const pairingSucess = () => ({
  type: PAIRING_SUCCESS
});

export const pairingError = () => ({
  type: PAIRING_ERROR
});

export const localConnectionCheck = () => ({
  type: LOCAL_CONNECTION_CHECK
});

export const localFirmwareDownloadError = () => ({
  type: LOCAL_FIRMWARE_DOWNLOAD_ERROR
});

export const localFirmwareDownloadSuccess = () => ({
  type: LOCAL_FIRMWARE_DOWNLOAD_SUCCESS
});

export const localFirmwareUpdateRequest = () => ({
  type: LOCAL_FIRMWARE_UPDATE_REQUEST
});

export const localFirmwareUpdateSucess = () => ({
  type: LOCAL_FIRMWARE_UPDATE_SUCCESS
});

export const localFirmwareUpdateError = () => ({
  type: LOCAL_FIRMWARE_UPDATE_ERROR
});
