import React from 'react';
import PrismicReact from 'prismic-reactjs';
import TextStandard from './TextStandard';
import ImageStandard from './ImageStandard';
import Card from '../Elements/Card';
import {Container, Row, Col} from 'reactstrap';
import _ from 'lodash';


// const Loader = () =>{
//   return (
//     <h2>Loading ...</h2>
//   )
// }

class ContentBlock extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      items: []
    }
  }
  componentDidMount(){
    let {primary, items} = this.props.slice;
    let collection_items = _.map(items, "collection_item");
    let ids = _.map(collection_items, "id");
    let _this = this;
    this.props.prismicCtx.api.getByIDs(ids).then(function(response) {
      _this.setState({items: response.results})
    });
  }

  render() {
    return this.state.items.length > 0 ?
      <div className = "collection slice">
          <Container className="content-row">
            <Row className="row-eq-height">
              { this.state.items.slice(0, 4).map((item, key)=>{
                  let type = item.type;
                  let cardItem;
                  switch(type){
                    case "blog":
                      cardItem = { url: item.data.blog_image.url , title: item.data.blog_title, text: "", type: "blog"}
                      break;
                    case "jot":
                      cardItem = { url: item.data.jot_image.url , title: item.data.jot_title, text: "", type: "jot"}
                      break;
                    case "product":
                      cardItem = { url: item.data.jot_image.url , title: item.data.jot_title, text: "", type: "product"}
                      break;
                  }
                  let jot = item.data;
                  return (
                    <React.Fragment key={key}>
                    <Col xs="6" sm="3" className="d-flex align-items-stretch">
                      <Card {...cardItem} small/>
                    </Col>
                  </React.Fragment>
                  )
              })}
            </Row>
          </Container>
      </div>
    : null;
    
    
  }
}



// const ContentBlock = (props) => {
//   let {primary, items} = props.slice;
//   return(
//     <div className="collection">
//       <Container>
//         <Row>
//           <Col>
//             <h1>Test</h1>   
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   )

// }

export default ContentBlock;


