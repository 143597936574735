import React from "react";
import PrismicReact from "prismic-reactjs";
import { Container, Row, Col } from "reactstrap";
import Video from "../Elements/Video";
import FullScreen from "../Styled/FullScreen";
import {Heading, SubTitle, BodyText} from "../Styled/Type";
class Slices extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let { doc } = this.props;
    return (
      <>
        <FullScreen>
          <Video url={doc.data.video.url} />
          <div
            style={{
              position: "absolute",
              top: 0,
              width: this.state.width,
              height: this.state.height,
              display: "flex"
            }}
          >
            <Container className="d-flex align-items-stretch">
              <Row className={"justify-content-center align-self-center"}>
                <Col
                  sm={{ size: 5, offset: 5 }}
                  className="justify-content-center align-self-center"
                >
                  <SubTitle>
                    {PrismicReact.RichText.asText(doc.data.homepage_subtitle)}
                  </SubTitle>
                  <Heading>
                    {PrismicReact.RichText.asText(doc.data.homepage_title)}
                  </Heading>
                  <BodyText>{PrismicReact.RichText.asText(doc.data.body_text1)}</BodyText>
                </Col>
              </Row>
            </Container>
          </div>
        </FullScreen>
      </>
    );
  }
}

export default Slices;
