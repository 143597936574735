import types from './types';

export const jotGetRequest = () => ({
  type: types.JOT_GET_REQUEST
});

export const jotGetSuccess = jot => ({
  type: types.JOT_GET_SUCCESS,
  jot
});

export const jotGetError = err => ({
  type: types.JOT_GET_ERROR,
  err
});

export const jotUploadRequest = () => ({
  type: types.JOT_UPLOAD_REQUEST
});

export const jotUploadSuccess = jot => ({
  type: types.JOT_UPLOAD_SUCCESS,
  jot
});

export const jotUploadError = err => ({
  type: types.JOT_UPLOAD_ERROR,
  err
});

export const jotFetchImage = jotUrl => ({
  type: types.JOT_FETCH_IMAGE,
  jotUrl
});

export const jotCreateRequest = (svg, params) => ({
  type: types.JOT_CREATE_REQUEST,
  svg,
  params
});

export const jotCreateSuccess = jot => ({
  type: types.JOT_CREATE_SUCCESS,
  jot
});

export const jotCreateError = err => ({
  type: types.JOT_CREATE_ERROR,
  err
});

export const jotDraftWaiting = () => ({
  type: types.JOT_DRAFT_WAITING
});

export const jotDraftAdd = jot => ({
  type: types.JOT_DRAFT_ADD,
  jot
});

export const addToJoto = jot => ({
  type: types.ADD_TO_JOTO,
  jot
});


export const jotDraftRemove = () => ({
  type: types.JOT_DRAFT_REMOVE
});

export const jotProcessRequest = () => ({
  type: types.JOT_PROCESS_REQUEST
});

export const jotProcessSuccess = () => ({
  type: types.JOT_PROCESS_SUCCESS
});

export const jotProcessError = err => ({
  type:  types.JOT_PROCESS_ERROR,
  err
});

export const jotSendRequest = (jotId, deviceId) => ({
  type: types.JOT_SEND_REQUEST,
  jotId,
  deviceId
});

export const jotSendSuccess = jot => ({
  type:  types.JOT_SEND_SUCCESS,
  jot
});

export const jotSendError = err => ({
  type:  types.JOT_SEND_ERROR,
  err
});
