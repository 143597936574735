// In src/Page.js

import React from "react";
import NavBar from "../Containers/Navbar";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import { compose } from "recompose";
import Banner from "../Components/Elements/Banner";
import RichContent from "../Components/Elements/RichContent";
import { withFetchUID } from "../Prismic/Helpers";

const enhance = compose(withFetchUID("blog"));

const BlogComponent = ({ prismicCtx, doc, match }) => {
  const {
    data: {
      blog_title,
      subtitle,
      blog_image,
      // blog_banner_image_left,
      // blog_banner_image_right,
      body,
      ...rest
    } = {}
  } = doc || {};

  return doc ? (
    <BodyWrapper>
      <Banner prismic image={{ full: blog_image.url }} />
      <RichContent
        title={blog_title}
        subtitle={subtitle}
        body={body}
        {...rest}
      />
    </BodyWrapper>
  ) : (
    <h1>Loading</h1>
  );
};

export default enhance(BlogComponent);
