import types from './types';
import { WIFI_SUCCESS } from '../pairing/types'
import { combineReducers } from 'redux';

const contentReducer = (state = false, action) => {
  switch (action.type) {
    case types.CONTENT_REQUEST:
      return { ...state, loading: true };
    case types.CONTENT_SUCCESS:
      return { ...state, ...action.content };
    case types.CONTENT_ERROR:
      return { ...state, error: action.err.message };
    case types.PAIRING_STAGE:
      return { ...state, error: action.err.message };
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.CONTENT_REQUEST:
      return true;
    case types.CONTENT_SUCCESS:
      return false;
    case types.CONTENT_ERROR:
      return false;
    default:
      return state;
  }
};

const pairing = (state = 1, action) => {
  switch (action.type) {
    case WIFI_SUCCESS:
      return 2;
    default:
      return state;
  }
};

export default combineReducers({
  content: contentReducer,
  loading,
  pairing,
  // setup,
  // ink,
  // drawing
});

// const initialState = { content: false, loading: false}

// const contentReducer = (state = initialState, action) => {

//   switch (action.type) {
//     case types.CONTENT_REQUEST:
//       return { ...state, loading: true}
//     case types.CONTENT_SUCCESS:
//       return { ...state, content: action.content, loading: false}
//     case types.CONTENT_ERROR:
//       return { ...state, loading: false, error: action.err.message}
//     // case ErrorReset:
//     //   return { ...state, error: false}
//     default:
//       return state
//   }
// }

// export default contentReducer
