import React from 'react';
import PrismicReact from 'prismic-reactjs';
import {Container, Row, Col} from 'reactstrap';

const ImageStandard = ({SquareRetina = null, FullsizeRetina = null, Fullsize = null, Square = null, url}) => {


  let imageURL = Square ? Square.url : (Fullsize ? Fullsize.url : url);
  let imageRetinaURL = SquareRetina ? SquareRetina.url : (FullsizeRetina ? FullsizeRetina.url : url);

  return(
    <div className="image-standard">
      <img className="img-fluid mx-auto" src={imageURL} srcSet={`${imageRetinaURL} 300w, ${imageRetinaURL} 768w, ${imageRetinaURL} 1280w`} />

    </div>
  )
}

export default ImageStandard;