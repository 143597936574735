import * as actions from "./actions";
import { profileRequest } from "../profile/actions";

// import * as errorActions from '../systemError/actions';

import Amplify, { PubSub, Auth } from "aws-amplify";
import { AmplifyConfig } from "../../config/index";
import { history } from "../../store";

Amplify.configure(AmplifyConfig);

export const cognitoLogin = (email, password, redirect) => async dispatch => {
  dispatch(actions.loginRequest());
  let usernameTrim = email.toLowerCase().trim();
  await Auth.signIn(usernameTrim, password)
    .then(async user => {
      switch (user.challengeName) {
        case "NEW_PASSWORD_REQUIRED":
          dispatch(actions.loginFurther(user));
          //NavigationService.navigate('reset');
          break;
        case "RESET_REQUIRED":
          dispatch(actions.loginFurther(user));
          //NavigationService.navigate('forgot');
          break;
        default:
          //NavigationService.navigate('App');
          dispatch(actions.loginSuccess(user));
          dispatch(profileRequest());
          redirect ? history.push(`/${redirect}`) : history.push("/profile");
          break;
      }
    })
    .catch(err => {
      dispatch(actions.loginError(err));
    });
};

export const cognitoLogout = () => (dispatch, getState) => {
  dispatch(actions.logoutRequest());
  Auth.signOut()
    .then(() => {
      //// NavigationService.navigate('Auth');
      dispatch(actions.logoutSuccess());
      dispatch({
        type: "RESET",
        state: { cart: getState().cart }
      });
      history.push("/login");
    })
    .catch(err => {
      dispatch(actions.logoutError(err));
    });
};

export const cognitoRegister = ({
  username,
  password,
  lastname,
  firstname
}) => dispatch => {
  dispatch(actions.registerRequest());
  const usernameTrim = username.toLowerCase().trim();
  const attributes = {};
  attributes["custom:firstname"] = firstname;
  attributes["custom:lastname"] = lastname;

  Auth.signUp({
    username: usernameTrim,
    password,
    attributes
  })
    .then(() => {
      dispatch(actions.registerSuccess(usernameTrim));
      //NavigationService.navigate('verify');
    })
    .catch(err => {
      dispatch(
        console.log({ title: "Registration Error", message: err.message })
      );
      dispatch(actions.registerError(err));
    });
};

export const cognitoVerify = (username, code) => dispatch => {
  Auth.confirmSignUp(username, code)
    .then(() => {
      dispatch(actions.verifySuccess());
      //NavigationService.navigate('login');
    })
    .catch(err => dispatch(actions.verifyError(err)));
};

export const cognitoResendVerify = username => dispatch =>
  Auth.resendSignUp(username)
    .then(() => dispatch(actions.resendVerifySuccess()))
    .catch(err => {
      dispatch(actions.resendVerifyError(err));
      dispatch(
        console.log({ title: "Verification Error", message: err.message })
      );
    });

export const passwordForgot = username => dispatch => {
  const login = username.toLowerCase().trim();
  dispatch(actions.passwordForgotRequest(login));
  Auth.forgotPassword(login)
    .then(data => dispatch(actions.passwordForgotSuccess(data)))
    .catch(err => {
      dispatch(actions.passwordForgotError(err));
      dispatch(console.log({ title: "Password Reset Error", message: err }));
    });
};

export const passwordReset = ({ username, code, password }) => dispatch => {
  dispatch(actions.passwordResetRequest());
  Auth.forgotPasswordSubmit(username.toLowerCase().trim(), code, password)
    .then(data => {
      dispatch(actions.passwordResetSuccess());
      // dispatch(actions.cognitoLogin(username, password));
    })
    .catch(err => {
      dispatch(actions.passwordResetError(err));
      dispatch(console.log({ title: "Password Reset Error", message: err }));
    });
};

export const passwordForceReset = params => (dispatch, getState) => {
  dispatch(actions.passwordForceResetRequest());
  let user = getState().authReducer.user;
  Auth.completeNewPassword(
    user,
    params.new_password,
    user.challengeParam.requiredAttributes
  )
    .then(data => {
      dispatch(actions.passwordForceResetSuccess());
      dispatch(actions.loginSuccess(data));
    })
    .catch(err => dispatch(actions.passwordForceResetError(err)));
};


export const userAuthenticated = () => dispatch => {
   Auth.currentAuthenticatedUser()
  .then(response => dispatch(actions.loginSuccess()))
  .catch(err => dispatch(actions.logoutSuccess()))
}