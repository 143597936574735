import React from "react";
import BuyButton from "../Components/Styled/BuyButton";

import AceEditor from "react-ace";
import brace from "brace";
import "brace/mode/json";
import "brace/theme/monokai";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { scanWifiNetworks } from "../State/pairing/operations";

import { networkScanSuccess } from "../State/pairing/actions";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      scanning: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.setState({ scanning: true });
    scanWifiNetworks().then(response => {
      this.setState({ networks: response });
      this.props.networkScanSuccess(response);
      this.setState({ scanning: false });
    });
  }

  render() {
    return (
      <>
        <h2>Wifi</h2>

        <AceEditor
          value={JSON.stringify(this.state.networks, null, "\t")}
          style={{ width: "100%", height: 200 }}
          mode="json"
          theme="monokai"
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
        />

        <BuyButton onClick={this.onFormSubmit} type="submit">
          {this.state.scanning ? (
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          ) : (
            "Scan Networks"
          )}
        </BuyButton>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    networks: state.pairing.networks
  };
};

const mapDispatchToProps = {
  networkScanSuccess
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleReactFileUpload);
