//DISCOVER REDUCER
export const USER_SEARCH_REQUEST = "app/users/USER_SEARCH_REQUEST";
export const USER_SEARCH_SUCCESS = "app/users/USER_SEARCH_SUCCESS";
export const USER_SEARCH_ERROR = "app/users/USER_SEARCH_ERROR";
export const USER_REFRESH = "app/users/USER_REFRESH";
export const USER_CREATE_REQUEST = "app/users/USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "app/users/USER_CREATE_SUCCESS";
export const USER_CREATE_ERROR = "app/users/USER_CREATE_ERROR";
export const USER_ENABLE_UPDATE_REQUEST = "app/users/USER_ENABLE_UPDATE_REQUEST";
export const USER_ENABLE_UPDATE_SUCCESS = "app/users/USER_ENABLE_UPDATE_SUCCESS";
export const USER_ENABLE_UPDATE_ERROR = "app/users/USER_ENABLE_UPDATE_ERROR";
export const USER_UPDATE_REQUEST = "app/users/USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "app/users/USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "app/users/USER_UPDATE_ERROR";
export const USER_GET_REQUEST = "app/users/USER_GET_REQUEST";
export const USER_GET_SUCCESS = "app/users/USER_GET_SUCCESS";
export const USER_GET_ERROR = "app/users/USER_GET_ERROR";
export const USERS_GET_REQUEST = "app/users/USERS_GET_REQUEST";
export const USERS_GET_SUCCESS = "app/users/USERS_GET_SUCCESS";
export const USERS_LIST_ERROR = "app/users/USERS_LIST_ERROR";

export default {
  USER_SEARCH_REQUEST,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_ERROR,
  USER_REFRESH,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  USER_ENABLE_UPDATE_REQUEST,
  USER_ENABLE_UPDATE_SUCCESS,
  USER_ENABLE_UPDATE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_LIST_ERROR
};