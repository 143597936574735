import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { logger } from "redux-logger";

// import { reducer as reduxFormReducer } from 'redux-form';
import * as allReducers from "./State";

import profileEpic from "./State/profile/epics";
import searchEpic from "./State/search/epics";
import createEpic from "./State/create/epics";

// import devicesEpic from './State/devices/epics';

import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxReset from 'redux-reset';

import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { connectRouter } from "connected-react-router";

//PERSIST
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native

import { combineEpics } from "redux-observable";
import { createEpicMiddleware } from "redux-observable";

const rootEpic = combineEpics(profileEpic, searchEpic, createEpic);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"]
};


// combineReducer applied on persisted(counterReducer) and NavigationReducer
const rootReducers = history =>
  combineReducers({
    ...allReducers,
    router: connectRouter(history)
  });
  

const epicMiddleware = createEpicMiddleware();
let middleware = [thunk, epicMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const persistedReducer = persistReducer(persistConfig, rootReducers(history));
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleware
      ),
      reduxReset()
    )
  );

  epicMiddleware.run(rootEpic);
  let persistor = persistStore(store);
  return { store, persistor };
}

// const configureStore = () => {
//     return { persistor, store };
// }
