//DISCOVER REDUCER
export const SEARCH_REQUEST = "app/discover/SEARCH_REQUEST";
export const SEARCH_SUCCESS = "app/discover/SEARCH_SUCCESS";
export const SEARCH_ERROR = "app/discover/SEARCH_ERROR";

export const REQUEST_JOT_PAGE = "app/discover/REQUEST_JOT_PAGE";
export const RECEIVE_JOT_PAGE = "app/discover/RECEIVE_JOT_PAGE";
export const RECEIVE_ALL_JOTS = "app/discover/RECEIVE_ALL_JOTS";
export const REFRESH_JOT_PAGE = "app/discover/REFRESH_JOT_PAGE";

export default {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  REQUEST_JOT_PAGE,
  RECEIVE_JOT_PAGE,
  RECEIVE_ALL_JOTS,
  REFRESH_JOT_PAGE
};
