import React, {Component} from 'react';
import {LineItem, LineItemContent, LineItemContentRow, LineItemImage, LineItemPrice, LineItemQuantity, LineItemQuantityContainer, LineItemQuantityUpdate, LineItemQuantityUpdateForm, LineItemRemove, LineItemTitle, LineItemVariantTitle} from '../Styled/LineItem';

import { withHandlers, compose } from "recompose";



  const LineItemComponent = ({line_item, removeLineItemInCart, decrementQuantity, incrementQuantity}) => {
    return (
      <LineItem>
        <LineItemImage>
          {line_item.variant.image ? <img src={line_item.variant.image.src} alt={`${line_item.title} product shot`}/> : null}
        </LineItemImage>
        <LineItemContent>
          <LineItemContentRow>
            <LineItemVariantTitle>
              {line_item.variant.title}
            </LineItemVariantTitle>
            <LineItemTitle>
              {line_item.title}
            </LineItemTitle>
          </LineItemContentRow>
          <LineItemContentRow>
            <LineItemQuantityContainer>
              <LineItemQuantityUpdate onClick={decrementQuantity}>-</LineItemQuantityUpdate>
              <LineItemQuantity>{line_item.quantity}</LineItemQuantity>
              <LineItemQuantityUpdate  onClick={incrementQuantity}>+</LineItemQuantityUpdate>
            </LineItemQuantityContainer>
            <LineItemPrice>
              £ { (line_item.quantity * line_item.variant.price).toFixed(2) }
            </LineItemPrice>
            <LineItemRemove onClick={()=> removeLineItemInCart(line_item.id)}>×</LineItemRemove>
          </LineItemContentRow>
        </LineItemContent>
      </LineItem>
    );
  }



export default LineItemComponent;
