import React from "react";

const Section = props => {
  let { section_color, pattern } = props;
  let light_color = section_color === "#ffffff" ? false : true;
  return (
    <div
      className={`section ${light_color && "color_section"}`}
      style={{ backgroundColor: section_color, position: "relative", width: "100%" }}
    >
      <div
        className="pattern"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          backgroundImage: `url(${pattern.url})`,
          opacity: 0.1
        }}
      />
      {props.children}
    </div>
  );
};

export default Section;
