import { combineReducers } from 'redux';
import { ONLINE, OFFLINE } from './types';
import { MQTT_CONNECT } from '../mqtt/types';
 
const initialised = (state = false, action) => {
  switch (action.type) {
    case MQTT_CONNECT:
      return true;
    default:
      return state;
  }
};

const online = (state = true, action) => {
  switch (action.type) {
    case ONLINE:
      return true;
    case OFFLINE:
      return false;
    default:
      return state;
  }
};

const mqtt = (state = false, action) => {
  switch (action.type) {
    case MQTT_CONNECT:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  online,
  mqtt,
  initialised
});

// const initialState = {
//   // connection: false,
//   online: false
//   // ping: false,
//   // error: false,
//   // slow: false,
//   // mqtt: false
// };

// const connectionReducer = (state = initialState, action) => {
//   switch (action.type) {
//     // case MQTT_CONNECT:
//     //   return { ...state, mqtt: true };
//     case ONLINE:
//       return { ...state, online: true };
//     case OFFLINE:
//       return { ...state, online: false };
//     // case CONNECTED:
//     //   return { ...state, connection: true };
//     // case DISCONNECTED:
//     //   return { ...state, connection: false, ping: false };
//     // case PING:
//     //   return { ...state, ping: action.payload, error: false };
//     // case SLOW:
//     //   return { ...state, slow: true };
//     // case PING_ERROR:
//     //   return { ...state, ping: false, error: action.pingError };
//     default:
//       return state;
//   }
// };

// export default connectionReducer;