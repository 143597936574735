import React, { Component } from "react";
import paper, {
  Color,
  Tool,
  Path,
  Point,
  Symbol,
  SymbolDefinition,
  SymbolItem,
  Group
} from "paper-jsdom";

const styles = {
  icon: {
    height: 70,
    width: 70,
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iconSelected: {
    height: 70,
    width: 70,
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#e3e3e3"
  },
  selected: {
    background: "#e3e3e3"
  }
};

const rotate_svg = `<svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
  .st0{fill:none;stroke:red;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<polyline class="st0" points="27.8,18 22.6,20.3 20.2,15 "/>
<path class="st0" d="M9.6,24.7c-2.6-1-4.7-2.9-5.9-5.6c-2.4-5.4,0-11.7,5.4-14.1s11.7,0,14.1,5.4c1.4,3.2,1.2,6.7-0.4,9.5"/>
</svg>`;

const scale_svg = `<svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FFFFFF;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st1{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<line class="st0" x1="4.7" y1="3.7" x2="25.7" y2="24.7"/>
<polyline class="st1" points="4.2,10.1 4.2,3.3 11.2,3.3 "/>
<polyline class="st1" points="25.8,17.9 25.8,24.7 18.8,24.7 "/>
</svg>
`;

let hitOptions = {
  segments: true,
  stroke: true,
  curves: true,
  center: false,
  fill: true,
  guide: false,
  handles: true,
  tolerance: 15,
  bounding: true
};

const addScaleHandle = selectionRectangle => {
  let handle1 = new Path.Circle({
    radius: 20,
    fillColor: "#FF6161",
    name: "scale"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(scale_svg, {
      onLoad: function(item) {
        item.name = "svg_colibri";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let group = new Group([handle1, item]);
        group.applyMatrix = false;
        let definition = new SymbolDefinition(group);
        let scaleSymbol = new SymbolItem(definition);
        scaleSymbol.position = new Point(selectionRectangle.bounds.topLeft);
        scaleSymbol.name = "scale";
        scaleSymbol.scale(0.75);
        scaleSymbol.opacity = 0.85;

        resolve(scaleSymbol);
      }
    });
  });
};

const addRotateHandle = selectionRectangle => {
  let handle2 = new Path.Circle({
    radius: 20,
    fillColor: "#4697F9",
    name: "rotate"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(rotate_svg, {
      onLoad: function(item) {
        item.name = "svg_colibri";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let group = new Group([handle2, item]);
        group.applyMatrix = false;
        let definition = new SymbolDefinition(group);
        let rotateSymbol = new SymbolItem(definition);
        rotateSymbol.position = new Point(selectionRectangle.bounds.topRight);
        rotateSymbol.name = "rotate";
        rotateSymbol.scale(0.75);
        rotateSymbol.opacity = 0.85;

        resolve(rotateSymbol);
      }
    });
  });
};

const traverseHitResult = (hitResult, selectedPathGroup) => {
  if (hitResult.item.parent !== null) {
    if (hitResult.item.parent.parent !== null) {
      if (hitResult.item.parent.parent.parent !== null) {
        if (hitResult.item.parent.parent.parent.parent !== null) {
          if (hitResult.item.parent.parent.parent.parent.parent !== null) {
            if (
              hitResult.item.parent.parent.parent.parent.parent.parent !== null
            ) {
              if (
                hitResult.item.parent.parent.parent.parent.parent.parent
                  .parent !== null
              ) {
                return selectedPathGroup.parent.parent.parent.parent.parent
                  .parent;
              } else {
                return selectedPathGroup.parent.parent.parent.parent.parent;
              }
            } else {
              return selectedPathGroup.parent.parent.parent.parent;
            }
          } else {
            return selectedPathGroup.parent.parent.parent;
          }
        } else {
          return selectedPathGroup.parent.parent;
        }
      } else {
        return selectedPathGroup.parent;
      }
    } else {
      return selectedPathGroup;
    }
  } else {
    // console.log("no parent?!");
  }
};

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      type: null,
      nodeTool: false
    };
    this.doubleClickTime = 0;
    this.threshold = 100;
    this.activateTool = this.activateTool.bind(this);

    this.removeSelection = this.removeSelection.bind(this);
    this.onClick = this.onClick.bind(this);
    this.scale = this.scale.bind(this);
    this.scaleObject = this.scaleObject.bind(this);

    this.initSelectionRectangle = this.initSelectionRectangle.bind(this);
    this.updateSelection = this.updateSelection.bind(this);

    this.last_rotation = 0;
    this.last_scale = 0;
    this.scaleSymbol = null;
    this.rotateSymbol = null;
    this.dragging = false;
    this.selectionRectangle = null;
    this.selectedPathGroup = null;
    this.selectionRectangleScale = null;
    this.selectionRectangleRotation = null;
  }

  removeSelection = () => {
    if (this.selectionRectangle !== null) {
      this.scaleSymbol.remove();
      this.rotateSymbol.remove();
      this.selectionRectangle.remove();
      this.selectionRectangle = null;
    }
  };

  onClick() {
    this.removeSelection();
    this.activateTool();
  }

  scale(event) {
    this.selectionRectangleScale = event.point.subtract(
      this.selectionRectangle.bounds.center
    );
  }

  scaleObject(event){
    if (!this.dragging) {
      this.scaleSymbol.position = event.point;
      let scale = (event.point.x - this.selectionRectangle.bounds.center.x) /
        this.selectionRectangleScale.x;

      if (this.last_scale) {
        this.selectionRectangle.scaling = 1 / this.last_scale;
        this.selectionRectangle.selectedGroup.scaling = 1 / this.last_scale;
      }
      this.selectionRectangle.scaling = scale;
      this.selectionRectangle.selectedGroup.scaling = scale;
      this.scaleSymbol.position = this.selectionRectangle.segments[1].point;
      this.rotateSymbol.position = this.selectionRectangle.segments[2].point;
      this.last_scale = scale;
    }
  }

  rotateObject(event){
        
        let rotAngle = event.point.subtract(this.selectionRectangle.pivot).angle;
        if(!this.last_rotation){
          this.last_rotation = rotAngle;
        }
        this.selectionRectangle.rotate(rotAngle - this.last_rotation, this.selectionRectangle.pivot );
        this.selectionRectangle.selectedGroup.rotate(rotAngle - this.last_rotation, this.selectionRectangle.pivot);
        this.scaleSymbol.position =  this.selectionRectangle.segments[1].point;
        this.rotateSymbol.position =  this.selectionRectangle.segments[2].point;
        this.last_rotation = rotAngle;
  }

  async initSelectionRectangle(selectedObjects) {

    if (this.selectionRectangle !== null) {
      this.removeSelection();
    }

    let newSelection = new Path.Rectangle(selectedObjects.bounds.expand(50, 50));

    newSelection.pivot = newSelection.position;
    newSelection.strokeWidth = 2;
    newSelection.strokeColor = "#9CDBF7";
    newSelection.opacity = "0.5";
    newSelection.strokeScaling = false;
    newSelection.strokeCap = "round";
    newSelection.name = "selectionRectangle";
    newSelection.selected = false;
    newSelection.selectedGroup = selectedObjects;
    newSelection.selectedGroup.pivot = newSelection.pivot;

    this.selectionRectangle = newSelection;

    this.scaleSymbol = await addScaleHandle(this.selectionRectangle);
    this.rotateSymbol = await addRotateHandle(this.selectionRectangle);
    let _this = this;

    this.scaleSymbol.onMouseDrag = function(event) {
      _this.scaleObject(event);
    };
    
    this.rotateSymbol.onMouseDrag = function(event) {
      _this.rotateObject(event);
    };
  }

  updateSelection(event) {

    let {selectionRectangle} = this;

    if(selectionRectangle){
      this.dragging = true;
      selectionRectangle.position.x += event.delta.x;
      selectionRectangle.position.y += event.delta.y;
      selectionRectangle.selectedGroup.position.x += event.delta.x;
      selectionRectangle.selectedGroup.position.y += event.delta.y;
      this.scaleSymbol.position = selectionRectangle.segments[1].point;
      this.rotateSymbol.position = selectionRectangle.segments[2].point;
    }
  }

  activateTool() {
    let _this = this;
    if (paper.tool) paper.tool.remove();
    let tool = new Tool();

    tool.on("export", function(e) {
      _this.removeSelection();
    });


    tool.onMouseDown = event => {
      let hitResult = paper.project.activeLayer.hitTest(event.point, hitOptions);
      if (hitResult) {
        this.selectedPathGroup = hitResult.item;
        switch (hitResult.item.name) {
          case "rotate":
            this.selectionRectangleRotation = 0;
            break;
          case "scale":
            this.scale(event);
            break;
          default:
          !this.selectionRectangle && this.initSelectionRectangle(
              traverseHitResult(hitResult, this.selectedPathGroup)
            );
            this.last_rotation = 0;
            break;
        }
      } else {
        this.removeSelection();
      }
    };

    tool.onMouseDrag = event => {
      let{selectionRectangleScale, selectionRectangleRotation, selectedPathGroup, updateSelection} = this;
      
      if (selectionRectangleScale != null) return;
      else if (selectionRectangleRotation != null) return;
      else if (selectedPathGroup) updateSelection(event);
    };

    tool.onMouseUp = event => {
      this.last_scale = 0;
      this.selectionRectangleScale = null;
      this.selectionRectangleRotation = null;
      this.dragging = false;
    };

    tool.activate();
  }

  render() {
    const {
      handleClick,
      handleChange,
      onClick,
      toolName,
      toolSettings,
      ...rest
    } = this.props;

    return (
      <div className="tool">
        <a id="Select" {...rest} onClick={this.onClick}>
          <span className="icon-move" />
        </a>
      </div>
    );
  }
}

export default Select;
