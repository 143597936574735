import types from './types';
import _ from 'lodash';
import { combineReducers } from 'redux';

const objFromArray = array => {
  return array.reduce((accumulator, current) => {
    accumulator[current.Username] = current;
    return accumulator;
  }, {});
};

const idArray = array => {
  return array.map(user => user.Username);
};

const updateCurrentPage = (state, action) => {
  const { payload } = action;
  const currentPage = payload.lastEvaluatedKey;
  return {
    ...state,
    ...currentPage
  };
};

const currentPage = (state = null, action) => {
  switch (action.type) {
    case types.USERS_GET_SUCCESS:
      return updateCurrentPage(state, action);
    default:
      return state;
  }
};

const pagination = combineReducers({
  //pages,
  // refreshing: refreshing,
  currentPage: currentPage
});

const getJotsById = (state, action) => {
  const { payload } = action;
  const users = objFromArray(payload.jots);

  return {
    ...state,
    ...users
  };
};

const addJotsById = (state, action) => {
  const { payload } = action;
  const users = objFromArray(payload.Users);

  return {
    ...state,
    ...users
  };
};

const jotsById = (state = {}, action) => {
  switch (action.type) {
    case types.USERS_GET_SUCCESS:
      return addJotsById(state, action);
    default:
      return state;
  }
};

const loading = (state = {}, action) => {
  switch (action.type) {
    case types.USERS_GET_REQUEST:
      return true;
    case types.USERS_GET_SUCCESS:
      return false;
    default:
      return state;
  }
};

// const getJotIds = (state, action) => {
//   const { payload } = action;
//   const jotIds = idArray(payload.jots);
//   return _.uniq(state.concat(jotIds));
// };

const addJotIds = (state, action) => {
  const { payload } = action;
  const userIds = idArray(payload.Users);
  return _.uniq(state.concat(userIds));
};

const allJotIds = (state = [], action) => {
  switch (action.type) {
    case types.USERS_GET_SUCCESS:
      return addJotIds(state, action);
    default:
      return state;
  }
};

const userReducer = combineReducers({
  byId: jotsById,
  allIds: allJotIds,
  loading,
  // filter,
  // sort,
  // offset,
  currentPage
});

export default combineReducers({
  users: userReducer
});
