// In src/Page.js

import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import Quote from "../Components/Elements/Quote";
import ProductLoop from "../Containers/ProductLoop";
import { openCart, closeCart } from "../State/cart/actions";
import { addVariantToCart, client } from "../State/cart/operations";
import BodyWrapper from "../Components/Styled/BodyWrapper";

const thisClient = client;

const quoteObject = {
  quote: '"The Etch-A-Sketch for the digital age."',
  author: "Dezeen Magazine"
};

const ShopPage = ({ loading, products, ...rest }) => {
  let devices = products.filter(product => product.productType === "Devices");
  let consumables = products.filter(
    product => product.productType === "Consumables"
  );
  let addons = products.filter(
    product =>
      product.productType === "Addons" ||
      product.productType === "Accessories" ||
      product.productType === "Subscription"
  );

  return (
    <BodyWrapper>
      { devices && <ProductLoop client={thisClient} type={"devices"} products={devices} {...rest} />}
      {addons && (
        <ProductLoop
          title={"Get the most out of Joto"}
          subtitle={"Addons"}
          type={"addons"}
          products={addons}
          {...rest}
        />
      )}
      <Quote {...quoteObject} {...rest} />
      {consumables && (
        <ProductLoop  type={"consumables"} products={consumables} {...rest} />
      )}
    </BodyWrapper>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.cart.isOpen,
    products: state.cart.products,
    cart: state.cart.cart,
    checkout: state.cart.checkout
  };
};

const mapDispatchToProps = {
  openCart,
  closeCart,
  addVariantToCart
};

const withComponentDidMount = lifecycle({
  componentDidMount() {
    document.body.classList.add("shop");
  }
});

const enhance = compose(
  withComponentDidMount,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ShopPage);
