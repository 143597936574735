import React from 'react';
import Carousel from '../Elements/Carousel';

const Features = (props) => {
  let {slice} = props;
  return(
        <Carousel slice={slice}/>   
  )
}

export default Features;