import React from "react";
import axios, { post } from "axios";
import { Auth } from "aws-amplify";
import BuyButton from "../Components/Styled/BuyButton";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const postData = (url = "", data = {}) => {
  // Default options are marked with *
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, cors, *same-origin
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
    //   redirect: 'follow', // manual, *follow, error
    referrer: "no-referrer-when-downgrade", // no-referrer, *client
    body: data // body data type must match "Content-Type" header
  }).then(response => response); // parses JSON response into native Javascript objects
};

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploading: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file);
  }
  onChange(e) {
    console.log("onChange");
    this.setState({ file: e.target.files[0] });
  }
  async componentDidMount() {
    const credentials = await Auth.currentCredentials();
    this.setState({ credentials });
  }
  fileUpload(file) {
    const url = "http://192.168.4.1/update";
    const formData = new FormData();
    formData.append("file", file);
    console.log("formData", formData);
    this.setState({ uploading: true });
    postData(url, formData)
      .then(response => {
        this.setState({ uploading: false });
      })
      .catch(err => alert(err.message));
  }

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <h2>Firmware Upload</h2>
        <input type="file" onChange={this.onChange} />

        <BuyButton type="submit">
          {this.state.uploading ? (
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          ) : (
            "Upload"
          )}
        </BuyButton>
      </form>
    );
  }
}

export default SimpleReactFileUpload;

//export default enhance(FirmwareUpdater);
