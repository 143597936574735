
import styled from "styled-components";


export const Notice = styled.div`
    background-color: ${props => props.theme.color.tints.quarter.second};
    color: black;
    padding: ${props => props.theme.spacing.s}rem ${props => props.theme.spacing.xs}rem;
    margin: ${props => props.theme.spacing.s}rem 0;
    border-radius: 5px;
    display: inline-block;
`;


export default Notice;