import styled from "styled-components";

export const StyledInput = styled.input`
    border-radius: 50px;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: "AvenirLTLight";
    font-size: 0.75rem;
    margin-top: ${props => props.theme.spacing.xs}rem;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px ${props=>props.theme.color.first};
        border-color: white;
    }
`

export const StyledTextArea = styled.textarea`
    border-radius: 15px;
    display: block;
    width: 100%;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: .75rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: "AvenirLTLight";
    font-size: 0.75rem;
    margin-top: ${props => props.theme.spacing.xs}rem;

    &:focus {
  /* outline: 2px ${props=>props.theme.color.first} solid; */
        outline: 0;
        box-shadow: 0 0 0 2px ${props=>props.theme.color.first};
        border-color: white;
    }
`

export const ErrorContainer = styled.div`
    font-size: 12px;
    color: red;
    position: absolute;
    padding: .115rem .75rem;
`