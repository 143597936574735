import Amplify, { API } from 'aws-amplify';
import { apiName } from '../config/index';
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

// GET JOTS
export const apiGetJots = ({ searchTerm, limit, offset }) => {
  return API.get(apiName, `/users/jots?title=${searchTerm}&limit=${limit}&offset=${offset}`).catch(
    err => console.log('ERROR', err.response)
  );
};

// GET JOT
export const apiGetJot = jotId => API.get(apiName, `/jots/${jotId}`);

// DELETE JOT
export const apiDeleteJot = jotId => API.del(apiName, `/jots/${jotId}`);
export const apiArchiveJot = jotId => API.post(apiName, `/jots/${jotId}/archive`);

// https://api.joto.io/jots/search?limit=1&offset=0
// https://api.joto.io/jots/search?limit=1&offset=1
// https://api.joto.io/jots/search?title=1548874671
// https://api.joto.io/jots/search?description=placeholder
// https://api.joto.io/jots/search?userId=028e2e00-2775-11e9-8bd5-0b2fb6bcae89
// https://api.joto.io/jots/search?tag=jot
// https://api.joto.io/jots/search?category=cat2

//SEARCH JOTS
export const apiSearchJots = ({ searchTerm, limit, offset }) => {
  return API.get(apiName, `/jots/search?title=${searchTerm}&limit=${limit}&offset=${offset}`);
};

export const apiSearchCategories = ({ searchTerm, limit, offset }) =>
  API.get(apiName, `/jots/search?category=${searchTerm}&limit=${limit}&offset=${offset}`);

export const apiSearchTags = ({ searchTerm, limit, offset }) =>
  API.get(apiName, `/jots/search?tag=${searchTerm}&limit=${limit}&offset=${offset}`);

// CREATE JOT
export const apiCreateJot = params => API.post(apiName, `/jots/`, { body: params });

// SEND JOT
export const apiSendJot = (deviceId, jotId) =>
  API.post(apiName, `/devices/${deviceId}/jot/${jotId}`);

// GET DEVICE SETTINGS
export const apiGetDeviceSettings = (deviceId, mqttId) => {
  return API.get(apiName, `/devices/${deviceId}/settings/?id=${mqttId}`).catch(err =>
    console.log('ERROR', err)
  );
};

// SET DEVICE SETTINGS
export const apiSetDeviceSettings = (deviceId, mqttId, params) =>
  API.put(apiName, `/devices/${deviceId}/settings/?id=${mqttId}`, { body: params });
// {
//   "pnu": 123
// }

// SAVE DEVICE SETTINGS
export const apiSaveDeviceSettings = (deviceId, mqttId, params) =>
  API.post(apiName, `/devices/${deviceId}/settings/?id=${mqttId}}`, { body: params });
// {
//   "reboot": true
// }

// GET USER DEVICES
export const apiGetDevices = () => API.get(apiName, `/users/devices`);

// GET USER PROFILE
export const apiGetProfile = () => API.get(apiName, `/users/profile`);

// SET USER PROFILE
export const apiSetProfile = params =>
  API.put(apiName, `/users/profile`, { body: params }).catch(err =>
    console.log('ERROR', err.response)
  );

// GET USER SETTINGS
export const apiGetUserSettings = () => API.get(apiName, `/users/settings`);

// SET USER SETTINGS
export const apiSetUserSettings = params => API.put(apiName, `/users/settings`, { body: params });

// GET USER PROFILE HISTOR
export const apiGetHistory = () => API.get(apiName, `/users/profile/history`);

// GET FIRMWARE
export const apiGetFirmwareLatest = () =>
  API.get(apiName, `/firmware/latest`).catch(err => console.log(err));

// GET FIRMWARE
export const apiUpdateDevice = (deviceId, params) =>
  API.post(apiName, `/devices/${deviceId}/update`).catch(err => console.log(err));

// DEVICE RPC ENDPOINTS
export const apiDeviceReset = deviceId => API.post(apiName, `/devices/${deviceId}/reset`);

// DEVICE RPC ENDPOINTS
export const apiDeviceCare = deviceId =>
  API.post(apiName, `/devices/${deviceId}/care`).catch(err => console.log('ERROR', err.response));

export const apiDeviceCancel = deviceId => API.post(apiName, `/devices/${deviceId}/cancel`);

// GET PUBLIC USER PROFILE
export const apiGetUserProfile = handle => {
  console.log(handle);
  return API.get(apiName, `/users/profile/${handle}`).catch(err => console.log(err.response));
};

// GET SEND GCODE
export const apiDeviceSendGcode = (deviceId, params) =>
  API.post(apiName, `/devices/${deviceId}/gcode/`, { body: params }).catch(err =>
    console.log(err.response)
  );


//ADMIN ONLY JOTS
export const apiGetAllDevices = (limit) =>
API.get(apiName, `/devices${limit ? "/?limit=" : "?limit=2500"}}`).catch(err => console.log(err.response));


//ADMIN ONLY JOTS
export const apiGetDeviceShadow = (thingName) => API.get('IOT', `/things/${thingName}/shadow`).catch(err => console.log(err.response));