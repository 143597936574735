import React from "react";
import axios, { post } from "axios";
import { Auth } from "aws-amplify";
import ReactJson from "react-json-view";
import AceEditor from "react-ace";
import brace from "brace";
import "brace/mode/json";
import "brace/theme/monokai";
import BuyButton from "../Components/Styled/BuyButton";

import {
  getConfig,
} from "../State/pairing/operations";

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    getConfig().then(response => {
      this.setState({ config: response.data });
    });
  }
  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }
  async componentDidMount() {
    const credentials = await Auth.currentCredentials();
    this.setState({ credentials });
  }
  fileUpload(file) {
    const url = "http://192.168.4.1/update";
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return post(url, formData, config);
  }

  render() {
    return (
      <>
        <h2>Config</h2>
        <AceEditor
          value={JSON.stringify(this.state.config, null, "\t")}
          style={{ width: "100%", height: 200 }}
          mode="json"
          theme="monokai"
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
        />

        <BuyButton onClick={this.onFormSubmit} type="submit">
          Get Device Config
        </BuyButton>
      </>
    );
  }
}

export default SimpleReactFileUpload;

//export default enhance(FirmwareUpdater);
