import { combineEpics, ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError, flatMap, mapTo, tap } from 'rxjs/operators';
import * as actions from './actions';
import {
  PROFILE_REQUEST,
  PROFILE_ERROR,
  PROFILE_UPDATE_REQUEST,
  HISTORY_REQUEST,
  HISTORY_ERROR,
  USER_JOTS_REQUEST,
  USER_JOTS_ERROR
} from './types';

import { apiGetJots, apiGetHistory, apiGetProfile, apiSetProfile } from '../../Api';

// const perist$ = action$ =>
//   action$.pipe(
//     ofType(LOGOUT_REQUEST),
//     tap(response => console.log('THIS SHOLD WORK')),
//     mergeMap(action =>
//       of({
//         type: 'RESET',
//         payload: 'test',
//         error: true
//       })
//     )
//   );

const fetchProfileEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_REQUEST),
    mergeMap(action =>
      from(apiGetProfile()).pipe(
        map(response => actions.profileSuccess(response)),
        catchError(error =>
          of({
            type: PROFILE_ERROR,
            payload: error,
            error: true
          })
        )
      )
    )
  );

// const updateProfileEpic = action$ =>
//   action$.pipe(
//     ofType(PROFILE_UPDATE_REQUEST),
//     mergeMap(action => {
//       const { profile } = action;
//       const { twitter, instagram, ...profileObj } = profile;
//       profileObj.socials = [
//         { type: 'Twitter', url: twitter || 'Twitter' },
//         { type: 'Instagram', url: instagram || 'Instagram' }
//       ];
//       return from(apiSetProfile(profileObj)).pipe(
//         map(response => actions.profileSuccess(profileObj)),
//         catchError(error =>
//           of({
//             type: PROFILE_ERROR,
//             payload: error,
//             error: true
//           })
//         )
//       );
//     })
//   );

const searchUserJotsEpic = action$ =>
  action$.pipe(
    ofType(USER_JOTS_REQUEST),
    mergeMap(action =>
      from(apiGetJots(action.payload)).pipe(
        flatMap(response => of(actions.userJotsSuccess(response))),
        catchError(error =>
          of({
            type: USER_JOTS_ERROR,
            payload: error,
            error: true
          })
        )
      )
    )
  );

// const fetchHistoryEpic = action$ =>
//   action$.pipe(
//     ofType(HISTORY_REQUEST),
//     mergeMap(action =>
//       from(apiGetHistory()).pipe(
//         map(response => actions.historySuccess(response)),
//         catchError(error =>
//           of({
//             type: HISTORY_ERROR,
//             payload: error,
//             error: true
//           })
//         )
//       )
//     )
//   );

export default combineEpics(
  fetchProfileEpic,
  // updateProfileEpic,
  searchUserJotsEpic,
  // fetchHistoryEpic,
  // perist$
);
