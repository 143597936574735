import types from './types';
import { combineReducers } from 'redux';
import _ from 'lodash';


const addSettingsById = (state, action) => {
  const { deviceId, settings } = action;
  return {
    ...state,
    [deviceId]: settings
  };
};

const updateSettingsById = (state, action) => {
  const { deviceId, shadow } = action;
  return {
    ...state,
    [deviceId]: {
      ...state[deviceId]
    }
  };
};

const removeSettingsById = (state, action) => {
  const { deviceId } = action;
  const { [deviceId]: value, ...newState } = state;
  return {
    ...newState
  };
};

const settingsById = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DEVICE_SETTINGS_SUCCESS:
      return addSettingsById(state, action);
    // case types.SAVE_DEVICE_SETTINGS_SUCCESS:
    //   return updateSettingsById(state, action);
    case types.SHADOW_UNSUBSCRIBE:
      return removeSettingsById(state, action);
    default:
      return state;
  }
};

const addSettingsId = (state, action) => {
  let { deviceId } = action;
  return _.uniq(state.concat(deviceId));
};

const removeSettingsId = (state, action) => {
  let { deviceId } = action;
  return _.uniq(state.concat(deviceId));
};

const allSettingsIds = (state = [], action) => {
  switch (action.type) {
    case types.GET_DEVICE_SETTINGS_SUCCESS:
      return addSettingsId(state, action);
    // case types.SHADOW_UNSUBSCRIBE:
    //   return removeSettingsId(state, action);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.SHADOW_UPDATE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: settingsById,
  allIds: allSettingsIds,
  loading
});

// devices: {
//   byId: {

//   }
//   allIds: ["JOTO_1231231", "JOTO_1231231"]
//   selected: "JOTO_1231231"
// }

// shadows: {
//   byId: {

//   }
//   allIds: ["JOTO_1231231", "JOTO_1231231"]
// }

// FROM A SELECTOR:

// JOTO_1231231: {
//   jot:
//   draftJot: ""
//   status: "idle"
//   connected: false
//   ping: 123,
//   firmware:
// }

// const original = {
//   foo: 'bar',
//   stack: 'overflow',
// };

// // If the name of the property to remove is constant
// const { stack, ...withoutFirst } = original;
// console.log(withoutFirst); // Will be { "foo": "bar" }

// const { [id]: state[id], ...newState } = state;
// console.log(newState); // Will be { "foo": "bar" }

// // To do a deep removal with property names from variables
// const deep = {
//   foo: 'bar',
//   c: {
//    x: 1,
//    y: 2
//   }
// };

// const parentKey = 'c';
// const childKey = 'y';
// // Remove the 'c' element from original
// const { [parentKey]: parentValue, ...noChild } = deep;
// // Remove the 'y' from the 'c' element
// const { [childKey]: removedValue, ...childWithout } = parentValue;
// // Merge back together
// const withoutThird = { ...noChild, [parentKey]: childWithout };
// console.log(withoutThird); // Will be { "foo": "bar", "c": { "x": 1 } }
