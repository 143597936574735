export const MQTT_MESSAGE = "app/mqtt/MQTT_MESSAGE";
export const MQTT_ERROR = "app/mqtt/MQTT_ERROR";
export const MQTT_RECONNECT = "app/mqtt/MQTT_RECONNECT";
export const MQTT_DISCONNECT = "app/mqtt/MQTT_DISCONNECT";
export const MQTT_CONNECT = "app/mqtt/MQTT_CONNECT";


export default {
  MQTT_MESSAGE,
  MQTT_ERROR,
  MQTT_RECONNECT,
  MQTT_DISCONNECT,
  MQTT_CONNECT
};
