
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const BodyWrapper = styled.div`
  /* height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
  // TODO:
  &.shop{
    background: #f4f4f4;
    padding-top: $spacing-m;
  } */
  /* flex: 1; */
  flex-direction: column;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

    
  ${breakpoint("md")`
       padding-top: ${props => props.feed ? 0 : "120px"};
  `}
`;

export default BodyWrapper;