import Amplify from 'aws-amplify';

export const identityPoolId = 'eu-west-2:ab768970-46ae-41fe-90b5-249a5af7c9f6';
export const region = 'eu-west-2';
export const userPoolId = 'eu-west-2_UBAj6O7iY';
export const clientId = '15afietd87060j3psris1gdsef';
export const debugLevel = 'debug';
export const mqttDebugLevel = true;
export const s3Bucket = 'those-jots-stage';
export const apiName = 'MyAPI';
export const apiEndpoint = 'https://stage-api.joto.io';
export const iotEndpoint = 'a1k0tdyi37dcvg-ats.iot.eu-west-2.amazonaws.com';
export const stage = 'staging';
export const imgixurl = 'https://joto-stage.imgix.net';
export const shopURL = 'https://feed.joto.rocks/store';
export const supportURL = 'https://support.joto.rocks';
export const feedURL = 'https://www.feed.joto.rocks/stream';
export const aboutURL = 'https://joto.rocks';

export const privacyURL = 'https://www.iubenda.com/privacy-policy/58991274/full-legal';
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

export const AmplifyConfig = {
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId: clientId // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    bucket: s3Bucket, // REQUIRED -  Amazon S3 bucket
    region,
    identityPoolId
  },
  API: {
    endpoints: [
      {
        name: apiName,
        endpoint: apiEndpoint
      }
    ],
    region // OPTIONAL -  Amazon service region
  },
  PubSub: {
    aws_pubsub_region: region,
    aws_pubsub_endpoint: `wss://${iotEndpoint}/mqtt`
  }
};

Amplify.configure(AmplifyConfig);
