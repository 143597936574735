// Helper styles for demo

import React from "react";
import { Formik, withFormik, ErrorMessage } from "formik";
import { Form, FormGroup, Label, FormText } from "reactstrap";
import {StyledInput, ErrorContainer} from '../Components/Styled/Form';
import Button from '../Components/Styled/BuyButton';
import Avatar from "react-avatar";
import { compose } from 'recompose';
import { connect } from "react-redux";
import {userJotsRequest} from "../State/profile/actions";
import { S3Image } from 'aws-amplify-react';
import S3AvatarUpload from "../Components/Elements/S3AvatarUpload";

import * as Yup from "yup";

const fileToKey = (data) => {
  const { name, size, type } = data;
  return 'test_' + name;
}

const MyForm = props => {
  const { values, handleChange, handleBlur, handleSubmit, image, user } = props;
  return (
    <Form onSubmit={handleSubmit}>
    <S3AvatarUpload image={image}/>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstname}
          name="firstname"
        />
        <ErrorContainer>
          <ErrorMessage name="firstname" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Last Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastname}
          name="lastname"
        />
        <ErrorContainer>
          <ErrorMessage name="lastname" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="textarea"
          placeholder="Bio"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.bio}
          name="bio"
        />
        <ErrorContainer>
          <ErrorMessage name="bio" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Website"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.website}
          name="website"
        />
        <ErrorContainer>
          <ErrorMessage name="website" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Twitter"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          name="email"
        />
        <ErrorContainer>
          <ErrorMessage name="email" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="password"
          placeholder="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          name="password"
        />
        <ErrorMessage name="password" />
      </FormGroup>
    </Form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({profile}) => ({ 
    firstname: profile.firstname, 
    lastname: profile.lastname, 
    bio: profile.bio, 
    handle: profile.handle, 
    website: profile.website, 
  }),
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required")
    }),
  handleSubmit: (values, { setSubmitting }) => {
    alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  },
  displayName: "BasicForm"
});


const mapStateToProps = state => {
  return {
    profile: state.profile.info,
    user: state.authentication.user.username,
    image: state.profile.info.image
  };
};

const mapDispatchToProps = {
  userJotsRequest
};


const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  MyEnhancedForm
)
export default enhance(MyForm);
