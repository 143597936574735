import createReducer from './reducers';

// import * as createSelectors from "./selectors";
import * as profileOperations from './operations';
import * as profileTypes from './types';
import profileEpics from './epics';

export { profileOperations, profileTypes, profileEpics };

export default createReducer;
