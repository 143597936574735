import styled from "styled-components";
import { darken } from 'polished'
import {Body} from "../Styled/Card";

const Component = styled.button`
  margin-bottom:  ${props => props.small ? props.theme.spacing.xs : props.theme.spacing.s}rem;
  background-color: ${props => props.theme.color[props.color] || props.theme.color.first};
  color: white;
  border: none;
  padding:  ${props => props.small ? props.theme.spacing.xs/3 :  props.theme.spacing.s/3 }rem;
  cursor: pointer;
  font-family: ${props => props.theme.font.button.family};
  border-radius: 50px;
  font-size: ${props => props.small ? props.theme.font.button.size : props.theme.font.button.size}rem;
  width: 100%;
  margin-top:  ${props => props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;
  text-align: center;
  text-transform: uppercase;
  max-width: 200px;
  
  &:hover,
  &:focus {
    background-color: ${props => darken(0.20, props.theme.color[props.color] || props.theme.color.first)};
  }

  &:disabled {
    background: #bfbfbf;
    cursor: not-allowed;
  }

  ${Body} &{
    margin-bottom: 0;
  }

`;

export default Component;

