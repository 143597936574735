import React from "react";
import Addon from "../Components/Elements/Addon";
import Device from "../Components/Elements/Device";
import Consumable from "../Components/Shopify/Consumable";
import { Container, Row, Col } from "reactstrap";
import { PulseLoader } from "react-spinners";

import { Title, SubTitle, SectionTitle } from "../Components/Styled/Type";
import Loader from "../Components/Styled/Loader";
import Section from "../Components/Styled/Section";
const ProductLoop = ({
  title,
  subtitle,
  type,
  products,
  addVariantToCart,
  loading,
  client
}) => {
  if (loading) {
    return (
      <Loader>
        <PulseLoader
          sizeUnit={"px"}
          size={5}
          color={"#000000"}
          loading={loading}
        />
      </Loader>
    );
  } else {
    switch (type) {
      case "addons":
        return (
        <Section>
            <Container>
              <Row>
                {title && (
                  <Col md={12}>
                    <SectionTitle>
                      {subtitle && <SubTitle>{subtitle}</SubTitle>}
                      {title && <Title>{title}</Title>}
                    </SectionTitle>
                  </Col>
                )}
              </Row>
              <Row className="">
                {products.map(product => (
                  <Addon
                    addVariantToCart={addVariantToCart}
                    key={product.id.toString()}
                    product={product}
                  />
                ))}
              </Row>
            </Container>
          </Section>
        );
      case "consumables":
        return (
          <Section>
            <Container>
              <Row>
                {title && (
                  <Col md={12}>
                    <SectionTitle>
                      {subtitle && <SubTitle>{subtitle}</SubTitle>}
                      {title && <Title>{title}</Title>}
                    </SectionTitle>
                  </Col>
                )}
              </Row>
              <Row className="">
                {products.map(product => (
                  <Consumable
                    addVariantToCart={addVariantToCart}
                    key={product.id.toString()}
                    product={product}
                  />
                ))}
              </Row>
            </Container>
          </Section>
        );
      case "devices":
        return (
          <Section white>
            <Container fluid>
                <Row className="">
                  {products.map(product => (
                    <Device
                    client={client}
                      addVariantToCart={addVariantToCart}
                      key={product.id.toString()}
                      product={product}
                    />
                  ))}
                </Row>
            </Container>
          </Section>
        );
      default:
        return null;
    }
  }
};

export default ProductLoop;
