export const PROFILE_REQUEST = 'app/profile/PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'app/profile/PROFILE_SUCCESS';
export const PROFILE_ERROR = 'app/profile/PROFILE_ERROR/';

export const HISTORY_REQUEST = 'app/profile/HISTORY_REQUEST';
export const HISTORY_SUCCESS = 'app/profile/HISTORY_SUCCESS';
export const HISTORY_ERROR = 'app/profile/HISTORY_ERROR/';

export const PROFILE_UPDATE_REQUEST = 'app/profile/PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'app/profile/PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'app/profile/PROFILE_UPDATE_ERROR';
export const USER_JOTS_REQUEST = 'app/profile/USER_JOTS_REQUEST';
export const USER_JOTS_SUCCESS = 'app/profile/USER_JOTS_SUCCESS';
export const USER_JOTS_REFRESH = 'app/profile/USER_JOTS_REFRESH';
export const USER_JOTS_ERROR = 'app/profile/USER_JOTS_ERROR';
export const ERROR_RESET = 'app/profile/ERROR_RESET';

export default {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  HISTORY_REQUEST,
  HISTORY_SUCCESS,
  HISTORY_ERROR,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
  USER_JOTS_REQUEST,
  USER_JOTS_SUCCESS,
  USER_JOTS_ERROR,
  USER_JOTS_REFRESH,
  ERROR_RESET
};
