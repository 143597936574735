
import styled from "styled-components";

const Quote = styled.div`
    width: 100%;
    background: ${props => props.theme.color.second};
    padding: ${props => props.theme.spacing.m}rem 0;

    text-align: center;

    h1, h2, h4, p{
        color: white;
        margin-bottom: ${props => props.theme.spacing.s}rem;
    }

`;

export default Quote;