import React from "react";
import axios, { post } from "axios";
import { Auth } from "aws-amplify";
import BuyButton from "../Components/Styled/BuyButton";
import { StyledInput } from "../Components/Styled/Form";
import { connect } from "react-redux";
import { pairDevice } from "../State/pairing/operations";

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      credentials: null,
      username: null
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    const { network, password } = this.state;
    pairDevice({
      network,
      password
    });
  }
  async componentDidMount() {
    const { _identityId: credentials } = await Auth.currentCredentials();
    const { username } = await Auth.currentAuthenticatedUser();
    this.setState({ credentials, username });
  }
  onChange(e) {
    this.setState({ password: e.target.value });
  }

  onSelect(e) {
    this.setState({ network: e.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <h2>Pair</h2>
        <p><strong>ID STRING: </strong>{`${this.state.username}=${
          this.state.credentials
        }`}</p>
        <select onChange={this.onSelect} style={{ width: "100%" }}>
          {this.props.networks &&
            this.props.networks.map((item, key) => {
              return (
                <option key={key} value={item.ssid}>
                  {item.ssid}
                </option>
              );
            })}
        </select>{" "}
        <StyledInput
          type="text"
          placeholder={"Wifi Password"}
          onChange={this.onChange}
        />
        <BuyButton type="submit">Pair Device</BuyButton>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    networks: state.pairing.networks
  };
};

const mapDispatchToProps = {
  //networkScanSuccess
};

export default connect(
  mapStateToProps,
  null
)(SimpleReactFileUpload);
