import React from "react";
import { Col } from "reactstrap";
import Card from '../Shopify/ProductCard';

const ProductAddon = ({product, addVariantToCart}) => {
  let {title, productType, descriptionHtml, images = [], variants = []} = product;
  let variantImage = images[0];
  let variant = variants[0];
  let {price, id} = variant;
  return(
    <Col sm={6} style={{ marginBottom: "2rem" }}
    >
      <Card title={title} variantImage={variantImage} text={descriptionHtml} price={price} type={productType} images={images} addVariantToCart={addVariantToCart} id={id} variantQuantity={1}/>
    </Col>
  )
}


export default ProductAddon;