import React, { Component } from 'react';

import AsyncSelect from 'react-select/lib/Async';

const colourOptions = [
  { label: "Color", value: "Color" }
]
const filterColors = (inputValue) => {
  return colourOptions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};


const customStyles = {

  control: (provided, state) => ({
    ...provided,
    borderRadius: '50px',
    fontFamily: "AvenirLTLight",
    padding: ".175rem .175rem",
    fontSize: "0.75rem"
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "AvenirLTLight",
    padding: ".175rem .75rem",
    fontSize: "0.75rem"
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#D9ECFC',
      borderRadius: "50px",
      fontFamily: "AvenirLTRoman",
      fontSize: "1rem",
      padding: ".075rem .575rem",
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      backgroundColor: '#D9ECFC',
      ':hover': {
        backgroundColor: '#D9ECFC',
      },
    };
  },
}


const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterColors(inputValue));
    }, 1000);
  });

export default class WithPromises extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(value) {
    this.props.onChange("categories", value.map(item => item.value));
  };

  handleBlur = () => {
    this.props.onBlur("categories", true);
  };
  
  render() {
    return (
      <AsyncSelect
      placeholder={"Categories..."}
        isMulti
        cacheOptions
        defaultOptions
        styles={customStyles}
        onChange={this.handleChange}
        loadOptions={promiseOptions}
      />
    );
  }
}