import types from './types';

//LOGIN ACTIONS
export const openCart = () => ({
  type: types.OPEN_CART
});

export const closeCart = () => ({
  type: types.CLOSE_CART
});

export const getCart = () => ({
  type: types.GET_CART
});

export const getShop = () => ({
  type: types.GET_SHOP
});


export const getProducts= () => ({
  type: types.GET_PRODUCTS
});


export const setCart = (cart) => ({
  type: types.SET_CART,
  cart
});

export const setShop = (shop) => ({
  type: types.SET_SHOP,
  shop
});

export const getCheckout = () => ({
  type: types.GET_CHECKOUT
});


export const setCheckout = (checkout) => ({
  type: types.SET_CHECKOUT,
  checkout
});


export const setProducts= (products) => ({
  type: types.SET_PRODUCTS,
  products
});
