import Amplify, { PubSub, Storage, Auth, API } from "aws-amplify";
import { AmplifyConfig } from "../../config/index";
import AWS from "aws-sdk";
import {
    userCreateRequest,
    userCreateSuccess,
    userCreateError,
    userUpdateEnabledRequest,
    userUpdateEnabledSuccess,
    userUpdateEnabledError,
    userUpdateSuccess,
    userUpdateError,
    userRequest,
    userSuccess,
    userError,
    userListRequest,
    userListSuccess,
    userListError
  } from "./actions";
  
Amplify.configure(AmplifyConfig);


//KEY VALUE PAIR MAPPING
export function keyValToArray(data) {
  var rObj = {};
  let reformattedArray = data.map(obj => {
    rObj[obj.Name] = obj.Value;
  });

  return rObj;
}

export function getUsers() {
  return dispatch => {
    dispatch(userListRequest());

    Auth.currentCredentials().then(credentials => {
      AWS.config.region = AmplifyConfig.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: AmplifyConfig.Auth.identityPoolId,
        Logins: credentials.params.Logins
      });
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

      // //MAY WANT TO MOVE THESE OUT OF THE FUNCTION
      var params = {
        //"Filter": "name ^= \"Jim\"",
        UserPoolId: AmplifyConfig.Auth.userPoolId
      };

      cognitoidentityserviceprovider.listUsers(params, function(err, data) {
        if (err) {
          console.log(err, err.stack);
          dispatch(userListError());
        } else {
          console.log(data);
          data.Users.map(user => {
            user.Attributes = keyValToArray(user.Attributes);
          });
          console.log(data);
          dispatch(userListSuccess(data));
        }
      });
    });
  };
}

///////////////////////////////////////////
//GET USER
////////////////////////////////////////

export function getUser(username) {
  return dispatch => {
    dispatch(userRequest());

    Auth.currentCredentials().then(credentials => {
      AWS.config.region = AmplifyConfig.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: AmplifyConfig.Auth.identityPoolId, // your identity pool id here
        Logins: credentials.params.Logins
      });

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      var params = {
        Username: username,
        UserPoolId: AmplifyConfig.Auth.userPoolId
      };

      cognitoidentityserviceprovider.adminGetUser(params, function(err, user) {
        if (err) return dispatch(userError(err));
        console.log("USER RAW", user);
        let attributeObject = {};
        user.UserAttributes.forEach(function(obj) {
          attributeObject[obj.Name] = obj.Value;
        });
        dispatch(
          userSuccess({
            id: user.Username,
            status: user.UserStatus,
            attributes: attributeObject,
            enabled: user.Enabled
          })
        );
      });
    });
  };
}

////////////////////////////////////////////
//UPDATE USER
////////////////////////////////////////

export function userUpdate(userParams) {
  return dispatch => {
    //Don't want to rerender form? But want a loader?
    //dispatch(userUpdateRequest());

    Auth.currentCredentials().then(credentials => {
      AWS.config.region = AmplifyConfig.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: AmplifyConfig.Auth.identityPoolId, // your identity pool id here
        Logins: credentials.params.Logins
      });

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

      //AWS COGNITO IS EXPECTING AN ARRAY
      var attrArray = [];

      for (var key in userParams.attributes) {
        if (userParams.attributes.hasOwnProperty(key)) {
          if (key !== "sub") {
            attrArray.push({
              Name: key,
              Value: userParams.attributes[key]
            });
          }
        }
      }

      var params = {
        Username: userParams.user,
        UserPoolId: AmplifyConfig.Auth.userPoolId,
        UserAttributes: attrArray
      };

      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(
        err,
        data
      ) {
        if (err) return dispatch(userUpdateError(err));
        dispatch(userUpdateSuccess());
        dispatch(getUsers());
      });
    });
  };
}

////////////////////////////////////////////
//UPDATE USER ENABLED STATUS
////////////////////////////////////////

export function userUpdateEnabled(enable) {
  return (dispatch, getState) => {
    dispatch(userUpdateEnabledRequest());

    Auth.currentCredentials().then(credentials => {
      AWS.config.region = AmplifyConfig.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: AmplifyConfig.Auth.identityPoolId, // your identity pool id here
        Logins: credentials.params.Logins
      });

      let currentUser = getState().userReducer.user;

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      var params = {
        Username: currentUser,
        UserPoolId: AmplifyConfig.Auth.userPoolId
      };

      if (enable) {
        cognitoidentityserviceprovider.adminEnableUser(params, function(
          err,
          data
        ) {
          if (err) return console.log(err);
          dispatch(userUpdateEnabledSuccess());
          dispatch(getUser(currentUser));
          dispatch(getUsers());
        });
      } else {
        cognitoidentityserviceprovider.adminDisableUser(params, function(
          err,
          data
        ) {
          if (err) return dispatch(userUpdateEnabledError(err));
          dispatch(userUpdateEnabledSuccess());
          dispatch(getUser(currentUser));
          dispatch(getUsers());
        });
      }
    });
  };
}

//////////////////////////
//CREATE USER THUNK
////////////////////////////

export function userCreate(values) {
  return dispatch => {
    dispatch(userCreateRequest());

    Auth.currentCredentials().then(credentials => {
      AWS.config.region = AmplifyConfig.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: AmplifyConfig.Auth.identityPoolId, // your identity pool id here
        Logins: credentials.params.Logins
      });

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      var awsParams = {
        DesiredDeliveryMediums: ["EMAIL"],
        ForceAliasCreation: false,
        MessageAction: !values.surpress ? "SUPPRESS" : undefined,
        TemporaryPassword: values.temporary,
        UserAttributes: [
          {
            Name: "email",
            Value: values.username
          }
        ],
        Username: values.username,
        UserPoolId: AmplifyConfig.Auth.userPoolId
      };

      cognitoidentityserviceprovider.adminCreateUser(awsParams, function(
        err,
        data
      ) {
        if (err) return dispatch(userCreateError(err));
        dispatch(userCreateSuccess());
      });
    });
  };
}
