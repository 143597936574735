import React, { Component } from "react";
import paper, { View, Path, Point, Size, Group, Layer } from "paper-jsdom";

import "./Paper.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.CanvasRef = React.createRef();
    this.state = {
      width: 400,
      height: 400
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(props, nextprops) {
    paper.project.importSVG(props.svg, {
      expandShapes: false,
      onLoad: function(item) {
        // Do something with item
        item.scale(0.3);
        item.strokeWidth = 2;
        item.strokeColor = "#00000";
        item.strokeScaling = false;
        item.fillColor = null;
        item.applyMatrix = true;
        item.strokeCap = "round";
        item.position = new Point(250, 250);
        item.pivot = new Point(250, 250);
        item.clipped = false;
        item.firstChild.remove();
      },
      onError: function(message) {
        console.error(message);
      }
    });
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    paper.setup("myCanvas");
    paper.settings.applyMatrix = true;
    paper.view.center = new Point(250, 250);

    let { width } = this.CanvasRef.current.getBoundingClientRect();

    let ratio = width / 500;
    paper.view.scaling = ratio;
    paper.view.draw();

    //CROP RECTANGLE
    let point = new Point(0, 0);
    let size = new Size(500, 500);
    let crop = new Path.Rectangle(point, size);

    crop.strokeWidth = 2;
    crop.strokeColor = "#e3e3e3";
    crop.dashArray = [4, 2];
    crop.fillColor = "white";

    let layer = new Layer();
    layer.activate();
  }

  exportSVG() {
    
    paper.tools.map(tool => tool.emit("export", null));

    return new Promise((resolve, reject) => {

    var shapes = paper.project.activeLayer.getItems({
      type: "shape"
    });

    var lines = paper.project.activeLayer.getItems({
      type: "line"
    });

    var polys = paper.project.activeLayer.getItems({
      type: "polyline"
    });

    var paths = paper.project.activeLayer.getItems({
      type: "path"
    });

    paper.project.activeLayer.removeChildren();

    let shapePaths = shapes.map(shape => {
      let newshape = shape.toPath();
      return newshape;
    });

    let linePaths = lines.map(line => {
      let newline = line.toPath();
      return newline;
    });

    let polyPaths = polys.map(poly => {
      let newpoly = poly.toPath();
      return newpoly;
    });

    let pathPaths = paths.map(path => {
      return path;
    });

    let newArray = shapePaths.concat(linePaths, polyPaths, pathPaths);

    var layer = new Layer(newArray);
    layer.activate();

    var items = layer.getItems({
      type: "path"
    });

    let point = new Point(0, 0);
    let size = new Size(500, 500);
    let crop = new Path.Rectangle(point, size);

    let cropped = items.reduce((result, child) => {
      child.splitAt(child.length);
      let newpath = child.intersect(crop, { stroke: true, trace: false });
      child.remove();
      newpath.strokeWidth = 2;
      newpath.strokeColor = "#0A0066";
      newpath.fillColor = null;
      if (newpath.length > 0 && newpath.pathData && !newpath.isEmpty()) {
        result.push(newpath);
      }
      return result;
    }, []);

    var group = new Group(cropped);
    crop.remove();

    let SVG = group.exportSVG({ asString: true });
    resolve(
      `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="500" height="500">${SVG}</svg>`
    )
    });
  }

  render() {
    return (
      <div ref={this.CanvasRef} className="App">
        <p className={"small"}>Crop area</p>
        <canvas id="myCanvas" width={500} height={500} />
      </div>
    );
  }
}

export default App;
