import React from "react";
import { connect } from "react-redux";
import {imgixurl} from '../config';
import { Container, Row, Col } from "reactstrap";
import Card from "../Components/Elements/Card";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { apiGetJot } from "../Api";
import Tags from "../Components/Elements/Tags";
import { Title, SubTitle, BodyText, Link } from "../Components/Styled/Type";
import Notice from "../Components/Styled/Notice";
import BuyButton from "../Components/Styled/BuyButton";
import { apiDeleteJot, apiArchiveJot } from "../Api";

// const mapStateToProps = state => {
//   return {
//     isOpen: state.cart.isOpen,
//     checkout: state.cart.checkout
//   };
// };

// const mapDispatchToProps = {

// };

const enhance = compose(
  // connect(
  //   mapStateToProps,
  //   mapDispatchToProps
  // ),
  withState("jot", "setJot", false),
  withHandlers({
    deleteJot: ({ jot: { jotId } }) => () => {
      console.log(jotId);
      apiDeleteJot(jotId);
    },
    archiveJot: ({ jot: { jotId } }) => () => {
      console.log(jotId);
      apiArchiveJot(jotId);
    }
  }),
  lifecycle({
    async componentDidMount() {
      let jot = await apiGetJot(this.props.match.params.id);
      this.props.setJot(jot);
    }
  })
);

const Edit = ({
  jot: {
    jotId,
    title,
    description,
    tags,
    categories,
    website,
    social,
    userId,
    pngFilename
  },
  deleteJot,
  archiveJot
}) => (
  <BodyWrapper>
    <Container className={"d-flex h-100 flex-grow-1"}>
      <Row className={"justify-content-center align-self-center flex-grow-1"}>
        <Col lg={6}>
          <Card
            create
            centered
            jot
            url={`${imgixurl}/protected/${encodeURIComponent(userId)}/jots/${pngFilename}?w=2000`}
          />
        </Col>
        <Col lg={{ size: 5, offset: 1 }}>
          {jotId && <SubTitle small>{jotId}</SubTitle>}
          {title && <Title small>{title}</Title>}
          {description && <BodyText>{description}</BodyText>}
          {website && <Link>{website}</Link>}
          {tags && <Tags tags={tags} />}
          {categories && <Tags isCategory tags={categories} />}
          <BuyButton color={"first"}>Update Jot</BuyButton>
          <BuyButton color={"second"} onClick={deleteJot}>
            Delete Jot
          </BuyButton>
          <BuyButton color={"third"} onClick={archiveJot}>
            Archive Jot
          </BuyButton>
        </Col>
      </Row>
    </Container>
  </BodyWrapper>
);

export default enhance(Edit);
