import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "react-router-scroll-top";

import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./store";

import NavBar from "./Containers/Navbar";
import Footer from "./Containers/Footer";

import NotFound from "./Screens/NotFound";
import Homepage from "./Screens/Homepage";
import About from "./Screens/About";
import Login from "./Screens/Login";
import Page from "./Screens/Page";
import Privacy from "./Screens/Privacy";
import Shop from "./Screens/Shop";
import VideoLoop from "./Screens/VideoLoop";
import Feed from "./Screens/Feed";
import Blog from "./Screens/Blog";
import BlogSingle from "./Screens/BlogSingle";
import Profile from "./Screens/Profile";
import Create from "./Screens/Create";
import Edit from "./Screens/Edit";
import Diagnostics from "./Screens/Diagnostics";
import { Auth } from "aws-amplify";
import { GlobalStyle } from "./styles/global";
import "aos/dist/aos.css";
import "./styles/fontfamily.scss";

const { store, persistor } = configureStore();

class PrivateRoute extends React.Component {
  state = {
    loading: true,
    isAuthenticated: false
  };

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(response =>
        this.setState({ isAuthenticated: true, loading: false })
      )
      .catch(err => this.setState({ isAuthenticated: false, loading: false }));
  }

  render() {
    const { component: Component, ...rest } = this.props;
    let { path } = this.props;
    path = path.replace('/', '');

    if (this.state.loading) {
      return <div>LOADING</div>;
    } else {
      return (
        <Route
          {...rest}
          render={props => (
            <>
              {!this.state.isAuthenticated && (
                <Redirect
                  to={{
                    pathname: `/login`,
                    search: `?redirect=${path}`,
                    state: { from: this.props.location }
                  }}
                />
              )}
              <Component {...props} />
            </>
          )}
        />
      );
    }
  }
}

const App = ({ rehydrated, prismicCtx, ...rest }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <Route
              component={props => <NavBar prismicCtx={prismicCtx} {...props} />}
            />
            <Switch>
              <Redirect exact from="/" to="/home" />
              <Redirect exact from="/info" to="/" />
              <Route
                path="/howto"
                component={() => {
                  window.location.href = "http://support.joto.rocks";
                  return null;
                }}
              />
              <Route
                path="/privacy"
                component={() => {
                  window.location.href =
                    "https://www.iubenda.com/privacy-policy/58991274/full-legal";
                  return null;
                }}
              />

              {/* <Redirect exact from="/home" to="/" /> */}
              {/* <Route
                exact
                path="/about"
                component={props => (
                  <About prismicCtx={prismicCtx} {...props} />
                )}
              /> */}
              <Route exact path="/login" component={Login} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/create" component={Create} />
              <PrivateRoute path="/diagnostics" component={Diagnostics} />
              <PrivateRoute
                path="/edit/:id"
                component={props => <Edit prismicCtx={prismicCtx} {...props} />}
              />
              <Route
                exact
                path="/stream"
                component={props => <Feed prismicCtx={prismicCtx} {...props} />}
              />
              <Route
                exact
                path="/discover"
                component={props => <Blog prismicCtx={prismicCtx} {...props} />}
              />
              <Route
                exact
                path="/discover/:id"
                component={props => (
                  <BlogSingle prismicCtx={prismicCtx} {...props} />
                )}
              />
              <Route
                exact
                path="/home"
                component={props => (
                  <Homepage prismicCtx={prismicCtx} {...props} />
                )}
              />
              <Route
                exact
                path="/privacy"
                component={props => <Privacy {...props} />}
              />
              <Route
                exact
                path="/store"
                component={props => <Shop prismicCtx={prismicCtx} {...props} />}
              />
               <Route
                exact
                path="/joto"
                component={props => <Shop prismicCtx={prismicCtx} {...props} />}
              />
              <Route exact path="/videoloop" component={VideoLoop} />
              <Route
                exact
                path="/:uid"
                component={props => <Page prismicCtx={prismicCtx} {...props} />}
              />
              <Route component={NotFound} />
            </Switch>
            <Route
              component={props => <Footer prismicCtx={prismicCtx} {...props} />}
            />
          </ScrollToTop>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
