import { combineReducers } from 'redux'
import { JOT_CREATE_REQUEST, JOT_CREATE_SUCCESS, JOT_CREATE_ERROR, JOT_DRAFT_ADD } from "./types";

const processing = (state = false, action) => {
  switch (action.type) {
    case JOT_CREATE_REQUEST:
      return true
    case JOT_CREATE_SUCCESS:
    case JOT_CREATE_ERROR:
      return false
    default:
      return state
  }
}

const draftJot = (state = null, action) => {
  switch (action.type) {
    case JOT_DRAFT_ADD:
      return action.jot.jot;
    default:
      return state;
  }
};

export default combineReducers({
  processing,
  draftJot
})


