// Helper styles for demo

import React from "react";
import { Formik, withFormik, ErrorMessage } from "formik";
import { Form, FormGroup, Label, FormText } from "reactstrap";
import {
  StyledInput,
  StyledTextArea,
  ErrorContainer
} from "../Components/Styled/Form";
import Tagger from "../Components/Elements/Tagger";
import Categories from "../Components/Elements/Categories";
import Avatar from "react-avatar";
import Button from "../Components/Styled/BuyButton";
import * as Yup from "yup";

const MyForm = props => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Jot Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          name="title"
        />
        <ErrorContainer>
          <ErrorMessage name="title" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledTextArea
          rows="3"
          type="textarea"
          placeholder="A bit of info about the work..."
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          name="description"
        />
        <ErrorContainer>
          <ErrorMessage name="description" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <Tagger
          value={values.topics}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.topics}
          touched={touched.topics}
        />
      </FormGroup>
      <FormGroup>
        <Categories
          value={values.topics}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.topics}
          touched={touched.topics}
        />
      </FormGroup>
      <Button type="submit" disabled={isSubmitting}>
        EXPORT
      </Button>
    </Form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({ title: "", description: "" }),
  validationSchema: () =>
    Yup.object().shape({
      title: Yup.string().required("Required"),
      description: Yup.string().max(120, "Over max 120 characters")
    }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.handleJotSubmit({ values });
    setSubmitting(false);
  },
  displayName: "BasicForm"
});

export default MyEnhancedForm(MyForm);
