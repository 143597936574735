export const CONNECTION_REQUEST = 'app/pairing/CONNECTION_REQUEST';
export const CONNECTION_SUCCESS = 'app/pairing/CONNECTION_SUCCESS';
export const CONNECTION_ERROR = 'app/pairingCONNECTION_ERROR/';
export const WIFI_REQUEST = 'app/pairing/WIFI_REQUEST';
export const WIFI_REFRESH = 'app/pairing/WIFI_REFRESH';
export const WIFI_SUCCESS = 'app/pairing/WIFI_SUCCESS';
export const WIFI_ERROR = 'app/pairing/WIFI_ERROR';
export const PAIRING_REQUEST = 'app/pairing/PAIRING_REQUEST';
export const PAIRING_SUCCESS = 'app/pairing/PAIRING_SUCCESS';
export const PAIRING_ERROR = 'app/pairing/PAIRING_ERROR';


export const LOCAL_FIRMWARE_DOWNLOAD_ERROR = 'app/pairing/LOCAL_FIRMWARE_DOWNLOAD_ERROR';
export const LOCAL_FIRMWARE_DOWNLOAD_SUCCESS = 'app/pairing/LOCAL_FIRMWARE_DOWNLOAD_SUCCESS';

export const LOCAL_CONNECTION_CHECK = 'app/pairing/LOCAL_CONNECTION_CHECK';
export const LOCAL_CONNECTION_ERROR = 'app/pairing/LOCAL_CONNECTION_ERROR';
export const LOCAL_CONNECTION_SUCCESS = 'app/pairing/LOCAL_CONNECTION_SUCCESS';

export const SET_WIFI_PASSWORD = 'app/pairing//SET_WIFI_PASSWORD';
export const SET_WIFI_NETWORK = 'app/pairing//SET_WIFI_NETWORK';

export const LOCAL_FIRMWARE_UPDATE_REQUEST = 'app/pairing//LOCAL_FIRMWARE_UPDATE_REQUEST';
export const LOCAL_FIRMWARE_UPDATE_ERROR = 'app/pairing//LOCAL_FIRMWARE_UPDATE_ERROR';
export const LOCAL_FIRMWARE_UPDATE_SUCCESS = 'app/pairing//LOCAL_FIRMWARE_UPDATE_SUCCESS';

export default {
  CONNECTION_REQUEST,
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,
  WIFI_REQUEST,
  WIFI_SUCCESS,
  WIFI_ERROR,
  PAIRING_REQUEST,
  PAIRING_SUCCESS,
  PAIRING_ERROR,
  LOCAL_CONNECTION_CHECK,
  LOCAL_CONNECTION_ERROR,
  LOCAL_CONNECTION_SUCCESS,
  SET_WIFI_PASSWORD,
  SET_WIFI_NETWORK,
  LOCAL_FIRMWARE_UPDATE_REQUEST,
  LOCAL_FIRMWARE_UPDATE_ERROR,
  LOCAL_FIRMWARE_UPDATE_SUCCESS,
  LOCAL_FIRMWARE_DOWNLOAD_ERROR,
  LOCAL_FIRMWARE_DOWNLOAD_SUCCESS,
  WIFI_REFRESH
};
