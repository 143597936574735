import React from "react";
import { Row, Col } from "reactstrap";
import { ProductImage, ProductPrice } from "../Shopify/ProductComponents";
import * as Card from "../../Components/Styled/Card";
import BuyButton from "../Styled/BuyButton";
import sanitizeHtml from "sanitize-html";

const AddToCart = ({ price, addVariantToCart, id, variantQuantity , trackingObject}) => (
  <Row>
    <Col md="6">
      <ProductPrice price={price} />
    </Col>
    <Col md="6">
      <BuyButton onClick={() => addVariantToCart(id, variantQuantity, trackingObject)}>
        Add to Cart
      </BuyButton>
    </Col>
  </Row>
);

const ComingSoon = () => (
  <Row>
    <Col md="12">
      <Card.Category> Coming soon </Card.Category>
    </Col>
  </Row>
);

const Content = ({ text }) => {
  let clean = sanitizeHtml(text, {
    allowedTags: ["b", "i", "em", "strong", "a"]
  });
  return (
    <span
      className="product-description"
      dangerouslySetInnerHTML={{
        __html: clean
      }}
    />
  );
};

const CardComponent = ({
  variantImage,
  title,
  images,
  type,
  text,
  price,
  small,
  id,
  ...rest
}) => {

  const trackingObject = {
    content_ids: [atob(id).replace('gid://shopify/ProductVariant/', '')],
    content_type: "product",
    currency: 'GBP',
    value: price
  };

  return (
    <Card.Container>
      <ProductImage images={images} variantImage={variantImage} alt={title} />
      <Card.Body>
        {type && <Card.Category> {type} </Card.Category>}
        {title && <Card.Title small={small}> {title} </Card.Title>}
        {text && (
          <Card.Text>
            <Content text={text} />
          </Card.Text>
        )}
        {price ? <AddToCart trackingObject={trackingObject} price={price} id={id} {...rest} /> : <ComingSoon />}
      </Card.Body>
    </Card.Container>
  );
};

export default CardComponent;
