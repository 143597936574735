import React, { Component } from "react";
import paper, { Project, Tool, Path } from "paper-jsdom";

class Clear extends Component {
  activateTool() {
    var selected = paper.project.activeLayer.removeChildren();
  }

  render() {
    return (
      <div className="tool">
        <a onClick={this.activateTool}>Clear</a>
      </div>
    );
  }
}

export default Clear;
