// In src/Page.js

import React from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import Slices from '../Containers/Slices'
import NotFound from '../Screens/NotFound';
import BodyWrapper from "../Components/Styled/BodyWrapper";

// Declare your component
export default class Page extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    console.log(props);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid

      return props.prismicCtx.api.getByUID('page', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }


  render() {
    if (this.state.doc) {
      let{data:{body}} = this.state.doc;
      return (
        <BodyWrapper>
          <Container>
            <Row>
              <Col md={12}>
              <Slices body={body} prismicCtx={this.props.prismicCtx} />
              </Col>
            </Row>
          </Container>
        </BodyWrapper>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <h1>Page</h1>;
  }
}