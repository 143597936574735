import types from './types';
import { combineReducers } from 'redux';

const connectionError = (state = false, action) => {
  switch (action.type) {
    case types.CONNECTION_ERROR:
      return true;
    case types.CONNECTION_SUCCESS:
    case types.ERROR_RESET:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.WIFI_REQUEST:
      return true;
    case types.WIFI_SUCCESS:
    case types.CONNECTION_SUCCESS:
    case types.WIFI_ERROR:
      return false;
    default:
      return state;
  }
};

const networks = (state = [], action) => {
  switch (action.type) {
    case types.WIFI_SUCCESS:
      return action.networks;
    case types.WIFI_REQUEST:
    case types.WIFI_ERROR:
      return [];
    default:
      return state;
  }
};

const scanning = (state = true, action) => {
  switch (action.type) {
    case types.WIFI_SUCCESS:
      return false;
    case types.WIFI_REQUEST:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  error: {
    connection: connectionError
  },
  networks,
  scanning
});
