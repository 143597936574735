import React from "react";
import VideoBanner from "../Components/Elements/VideoBanner.js";
import Slices from "../Containers/Slices.js";
import { withFetchPage } from "../Prismic/Helpers";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import { compose } from "recompose";
import LogoBar from "../Components/Elements/LogoBar";

const enhance = compose(withFetchPage("homepage"));

const HomepageComponent = ({
  doc,
  client,
  cartTotal,
  prismicCtx,
  openCart
}) => {
  let { data: { body = {} } = {} } = doc || false;

  return doc ? (
    <BodyWrapper data-wio-id={doc.id}>
        <VideoBanner className={doc.type} doc={doc} prismicCtx={prismicCtx} />
      <LogoBar />
      <Slices body={body} prismicCtx={prismicCtx} />
    </BodyWrapper>
  ) : (
    <h1>Loading</h1>
  );
};

export default enhance(HomepageComponent);
