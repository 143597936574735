
import styled from "styled-components";


export const FullScreen = styled.div`
   height: 80vh;
   width: 100vw;
`;


export default FullScreen;