import React, { Component } from "react";
import paper, {
  Tool,
} from "paper-jsdom";

const styles = {
  icon: {
    height: 70,
    width: 70,
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  selected: {
    background: "#e3e3e3"
  }
};

class SvgSymbol extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTarget: null,
      currentEventPoint: null
    };

    this.renderSymbol = this.renderSymbol.bind(this);
    this.activateTool = this.activateTool.bind(this);
  }

  componentWillMount = () => {};

  renderSymbol = () => {
    let _this = this;
    var position = _this.state.currentEventPoint;

    var svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1200 768" style="enable-background:new 0 0 1200 768;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:#FFD215;}
			.st1{fill:#020B80;}
			.st2{fill:none;stroke:#000000;stroke-width:0.9104;stroke-miterlimit:10;}
			.st3{fill:none;stroke:#000000;stroke-width:0.9067;stroke-miterlimit:10;}
			.st4{fill:none;stroke:#000000;stroke-width:0.8974;stroke-miterlimit:10;}
			.st5{fill:none;stroke:#000000;stroke-width:0.8967;stroke-miterlimit:10;}
			.st6{fill:#4D80FF;}
			.st7{fill:#FFD31B;}
		</style>
		<g>
			<path class="st5" d="M647.9,299c0,2.1,0,3.5,0.1,14.1"></path>
			<path class="st5" d="M648.9,298.8c2.4-2.1,6-1.8,8.1,0.6c0.8,0.9,1.2,1.9,1.4,3.1c0.3,2.7-0.5,6.1-2.3,8.2
				c-1.9,2.2-5.3,3.2-8.1,3.5"></path>
			<path class="st5" d="M587.3,201.4c-0.4,6.2-0.2,12.5,0.5,18.8l0.2-0.5"></path>
			<path class="st5" d="M587,211.1l10.7-0.9"></path>
			<path class="st5" d="M597.7,200.8l0.8,16.8"></path>
			<path class="st5" d="M607.4,202.2c-0.5-0.1-0.9,0.4-1.1,0.9c-0.6,1.5-1,3.1-1.1,4.8c-0.2,3.1,0.6,6.5,3.2,8.1
				c0.9,0.6,2.1,0.8,3,0.3c0.7-0.5,1.1-1.2,1.4-1.9c0.9-2.3,1.2-4.8,0.9-7.2c-0.4-2.4-1.6-4.6-3.5-6.2c-0.5-0.5-1.2-0.8-1.9-0.8
				c-0.9,0.1-1.5,0.9-2,1.7"></path>
			<path class="st5" d="M620.9,217.7l-0.4-14.1c0.4,0.3,0.8,0.7,1,1.2c2.2,4.5,4.9,8.8,8.2,12.6c0.6-5,0.8-10,0.6-15l0.2,0.5"></path>
			<path class="st5" d="M639.6,203.1c-0.2,4.8,0.1,9.5,0.9,14.2c0,0.2,0.1,0.4,0.2,0.6c0.4,0.6,1.3,0.5,2,0.3l4.6-1.2
				c0.3-0.1,0.6-0.2,0.8-0.4"></path>
			<path class="st5" d="M639.6,209.3c2.1-0.3,4.3-0.4,6.4-0.4"></path>
			<path class="st5" d="M639.9,202.2c2.9-0.3,5.9-0.2,8.8,0.2"></path>
			<path class="st5" d="M593.6,270.5c-0.2,4.8,0,9.5,0.8,14.2c0,0.2,0.1,0.4,0.2,0.6c0.4,0.6,1.2,0.5,1.9,0.3l4.3-1.2
				c0.2-0.1,0.5-0.2,0.8-0.4"></path>
			<path class="st5" d="M593.6,277.6c2-0.3,4.1-0.4,6.1-0.4"></path>
			<path class="st5" d="M594,269.6c2.5-0.3,5-0.3,7.4,0.1"></path>
			<path class="st5" d="M616.8,298.9c-0.2,4.4,0.1,8.8,0.8,13.1c0,0.2,0.1,0.4,0.2,0.5c0.4,0.6,1.2,0.5,1.9,0.3l4.2-1.1
				c0.2-0.1,0.5-0.2,0.7-0.3"></path>
			<path class="st5" d="M616.8,305.5c2-0.3,4-0.4,6-0.4"></path>
			<path class="st5" d="M617.2,298.1c2.4-0.3,4.9-0.2,7.3,0.1"></path>
			<path class="st5" d="M644,399.6c-0.2,4.9,0.1,9.9,0.9,14.8c0,0.2,0.1,0.4,0.2,0.6"></path>
			<path class="st5" d="M644.4,398.6c2.8-0.4,5.5-0.3,8.2,0.2"></path>
			<path class="st5" d="M659,400.2c-0.2,4.9,0.1,9.9,0.9,14.8c0,0.2,0.1,0.4,0.2,0.6c0.4,0.7,1.4,0.6,2.1,0.4l4.8-1.2"></path>
			<path class="st5" d="M659,407.6c2.2-0.3,4.4-0.4,6.7-0.4"></path>
			<path class="st5" d="M659.4,399.3c2.8-0.3,5.5-0.3,8.2,0.2"></path>
			<path class="st5" d="M656.5,201.2c0.3,3.3,1.8,6.4,4.3,8.6c0.2,0.2,0.6,0.3,0.7,0.1"></path>
			<path class="st5" d="M665.6,201.8l0.1-0.2c-1.5,6.1-3.4,12.1-5.6,17.9l0.2-0.5"></path>
			<path class="st5" d="M590.8,251.2c-0.2-1.3-0.2-2.6,0-3.9l1.2-11.2c2.6,2.8,3.9,6.4,6.2,9.5c0.1,0.2,0.4,0.4,0.6,0.5
				c0.6,0.1,0.9-0.6,1.1-1.2c0.6-2.9,1.8-5.6,3.4-8c0.2-0.4,0.8-0.8,1.1-0.6c0.1,0.1,0.2,0.3,0.3,0.5c1.6,4.8,0.7,10.1,2.2,14.8"></path>
			<path class="st5" d="M616.6,237.3c0.2,4.9,0.4,9.7,0.6,14.6"></path>
			<path class="st5" d="M627.9,237.2c-0.4,4.4-0.2,8.9,0.5,13.4c0,0.2,0.1,0.4,0.2,0.6c0.3,0.4,0.8,0.7,1.3,0.7
				c0.5-0.1,1.1-0.1,1.6-0.3c1.2-0.3,2.6-0.4,3.8-0.1"></path>
			<path class="st5" d="M642.9,236.8c0.2-0.2,0.2-0.6,0.2-0.9c-0.5,5.4-0.8,10.9-0.9,16.3l0.1-0.5"></path>
			<path class="st5" d="M644.5,246.3c-0.3-0.6,0.2-1.2,0.8-1.6c2.4-1.8,4.6-3.9,6.5-6.3"></path>
			<path class="st5" d="M644.9,247c3.2,0.5,6.3,1.9,8.9,4"></path>
			<path class="st5" d="M615.5,269.2c-1.8-0.5-3.8,0.4-4.9,1.7c-1.2,1.4-1.8,3.2-2.1,5c-0.3,1.7-0.2,3.4,0.2,5c0.5,1.6,1.7,3,3.2,3.6
				c1.6,0.6,3.6,0.1,4.5-1.4"></path>
			<path class="st5" d="M615.6,278.6c0.5-0.1,0.9,0.4,1.1,0.9c0.5,1.3,0.7,2.8,0.6,4.1"></path>
			<path class="st5" d="M629.9,269.2c-1.8-0.5-3.7,0.4-4.9,1.7c-1.2,1.4-1.8,3.2-2.1,5c-0.3,1.7-0.2,3.4,0.2,5c0.5,1.6,1.7,3,3.2,3.6
				c1.6,0.6,3.6,0.1,4.5-1.4"></path>
			<path class="st5" d="M629.9,278.6c0.5-0.1,0.9,0.4,1.1,0.9c0.5,1.3,0.7,2.8,0.6,4.1"></path>
			<path class="st5" d="M644.4,268.6c-1-0.6-2.2-0.8-3.3-0.3c-1.1,0.4-2,1.1-2.7,2.1c-0.7,0.9-1.2,2.1-0.8,3.2
				c0.4,0.7,0.9,1.2,1.5,1.6l3.9,2.9c0.9,0.7,1.8,1.4,2.4,2.4s0.5,2.4-0.3,3.1c-0.5,0.4-1.2,0.6-1.9,0.6c-1.4,0.1-2.9-0.1-4.2-0.6"></path>
			<path class="st5" d="M574.5,249c0,0,38.3-10.3,91.6-2.6"></path>
			<path class="st5" d="M637,297.6c-0.3,0.1-0.5,0.4-0.6,0.8l-4.2,11.2c-0.5,1.3-0.8,2.2-1.3,4"></path>
			<path class="st5" d="M637.9,297.8l1.1,5.8c0.4,2.1,0.8,4.2,1.2,6.3c0.3,1.3,0.7,2.7,1.5,3.8"></path>
			<path class="st5" d="M632.9,309.8c2.2-0.4,4.5-0.4,6.8-0.1"></path>
			<path class="st5" d="M602.1,299.1l1,14.1l0.2-0.3"></path>
			<path class="st5" d="M602.8,298.6c1-0.6,2.2-1,3.4-1.1c0.7-0.1,1.4,0,2.1,0.3c0.9,0.6,1.2,1.8,1.2,2.9c-0.1,1.3-0.8,2.6-1.8,3.4
				c-0.6,0.5-1.3,0.9-2.1,1.2l-1.3,0.6c2.7,1.8,5.1,4.1,7,6.6"></path>
			<path class="st5" d="M586.4,299.1c0.1,5.6,0.1,9.8,0.1,13.6c0-0.1,0-0.1,0.1-0.1"></path>
			<path class="st5" d="M586.2,297.8c2.2-1.2,3.2-1.1,4.5-0.7c1.1,0.3,1.8,1.1,2.2,2.2s0.2,2.4-0.6,3.2c-0.8,0.8-2,1.2-3.1,1.3
				c1.2-0.1,2.4-0.1,3.6,0.2c2.4,0.9,3.6,3.4,2.8,5.8c-0.2,0.4-0.4,0.9-0.7,1.2c-1.1,1.4-2.9,2.1-4.6,2.4c-1,0.2-2.1,0.4-3.1,0"></path>
			<path class="st5" d="M603.2,331.3c-0.2,6.4-0.6,7.8,0.5,14.1c-0.1,0.2,0.1,0.5,0.4,0.6c0.1,0,0.3,0,0.3-0.1l4.6-1.1"></path>
			<path class="st5" d="M617.5,331.1c-0.6,0.1-1.1,0.7-1.4,1.2c-1.4,2.4-1.8,5.1-1.2,7.8c0.2,1,0.6,2,1.2,2.8c0.6,0.8,1.6,1.4,2.7,1.4
				c1.3-0.1,2.4-0.7,3.2-1.7c0.7-1.1,1.2-2.2,1.5-3.4c0.4-1.4,0.8-2.9,0.4-4.4c-0.4-1.3-1.3-2.4-2.4-3.3c-0.7-0.6-1.6-1.2-2.5-1.1
				c-0.9,0.1-1.6,0.9-2.2,1.7"></path>
			<path class="st5" d="M600.1,398.4c-0.8,0.1-1.3,0.8-1.7,1.5c-1.6,2.8-2.1,6.2-1.4,9.4c0.2,1.2,0.8,2.4,1.5,3.4
				c0.8,1,1.9,1.6,3.2,1.7c1.5,0.1,2.9-0.9,3.8-2.1c0.8-1.2,1.4-2.7,1.8-4.2c0.5-1.7,0.9-3.6,0.4-5.3c-0.4-1.6-1.6-2.9-2.9-4
				c-0.9-0.8-1.9-1.4-3-1.2s-1.9,1.2-2.6,2.1"></path>
			<path class="st5" d="M631.1,332.1c-0.3,2.2-0.6,4.6-0.2,6.8s1.6,4.5,3.5,5.7c1.1,0.7,2.7,0.9,3.6,0c0.4-0.4,0.6-0.9,0.8-1.4
				c1.2-3.7,1.4-7.6,0.7-11.4"></path>
			<path class="st5" d="M646.1,332.7c0.1,0.1,0.1-0.2,0-0.2c-0.1,0.1-0.2,0.2-0.1,0.4c0.2,4.5,0.4,9,0.6,13.6"></path>
			<path class="st5" d="M647.4,332.3c1.6-0.4,3.2-0.5,4.7,0.3c1.4,0.8,2.3,2.6,1.8,4.1c-0.3,0.8-0.8,1.4-1.4,1.9
				c-1.1,1.1-2.4,2-3.8,2.7c1.8-0.1,3.4,0.8,4.9,1.7c1.5,1,2.8,2.1,4.3,3.1"></path>
			<path class="st5" d="M586.9,333.4c0.1,4.9,0.6,9.8,1.8,14.6"></path>
			<path class="st5" d="M586.4,332.1c2.2-0.9,4.6-1.2,7-1.2"></path>
			<path class="st5" d="M588.3,341.4c0.2-0.2,0.6-0.3,0.9-0.4c1.2-0.2,2.4-0.4,3.6-0.4"></path>
			<path class="st5" d="M616.4,399.6c-0.5,4.9-0.4,9.8,0.1,14.7"></path>
			<path class="st5" d="M616,398.2c2.3-0.6,4.7-0.8,7.1-0.4"></path>
			<path class="st5" d="M616.9,407.6c0.2-0.2,0.6-0.3,0.9-0.3c1.2-0.1,2.4-0.1,3.6,0"></path>
			<path class="st5" d="M630.5,400.2c-0.5,4.9-0.4,9.8,0.1,14.7"></path>
			<path class="st5" d="M630.1,398.8c2.3-0.6,4.7-0.8,7.1-0.4"></path>
			<path class="st5" d="M631,408.3c0.2-0.2,0.6-0.3,0.9-0.3c1.2-0.1,2.4-0.1,3.6,0"></path>
			<path class="st5" d="M588.5,397.8c-2.1-0.6-4.4,0.5-5.8,2.2c-1.4,1.7-1.9,3.9-2.2,6.1c-0.2,1.8-0.1,3.5,0.4,5.2s1.8,3.1,3.3,3.8
				c1.9,0.8,4.1,0.4,6.1,0"></path>
			<path class="st5" d="M566.5,367.1c3.9-0.5,7.8-0.7,11.8-0.6"></path>
			<path class="st5" d="M572.8,368c0.5,4.5,1.1,9,1.9,13.4"></path>
			<path class="st5" d="M585.6,367.4c-0.8,0.4-1.4,1.1-1.7,1.9c-0.8,1.7-1,3.6-0.7,5.4c0.1,1,0.5,1.9,1.1,2.7c1,1.4,2.9,1.8,4.3,0.8
				c0.2-0.2,0.5-0.4,0.7-0.6c0.6-0.8,1.1-1.6,1.2-2.6c0.4-1.8,0.3-3.6-0.5-5.2c-0.4-1.1-1.1-1.9-2.1-2.6c-1-0.6-2.2-0.6-3.2,0.1"></path>
			<path class="st5" d="M597.1,379.4c0.1-2.8,0-5.6-0.2-8.3c-0.1-1.4-0.3-2.9,0.1-4.2c2.1,1.8,2.9,4.9,5.1,6.6
				c1.1-2.4,2.5-4.7,4.2-6.8c0.4,0.2,0.6,0.8,0.6,1.2c0.4,3.7,1.2,7.3,2.2,10.9"></path>
			<path class="st5" d="M615.5,379.3c0.4-4.4,1.4-8.8,2.9-13c2.1,2.9,3.7,6.1,4.8,9.6c0.3,0.9,0.6,2,1.3,2.7"></path>
			<path class="st5" d="M617.2,376.5c1.6-0.7,3.3-0.9,5-0.6"></path>
			<path class="st5" d="M627.4,364.6c3.8-0.5,7.7-0.6,11.5-0.4"></path>
			<path class="st5" d="M633.4,366.4c-0.2,3.8-0.1,7.6,0.4,11.3"></path>
			<path class="st5" d="M645.3,366.6c-1.1,0.5-1.9,1.4-2.2,2.6c-0.3,1.1-0.4,2.3-0.4,3.4c0,1.4,0.3,2.8,0.9,4.1c0.7,1.2,2,2.2,3.4,2.1
				c1.4-0.1,2.6-1.3,3.2-2.5c1.1-1.7,1.4-3.6,1.1-5.6c-0.3-1.9-1.7-3.6-3.6-4.2c-1.2-0.4-2.8-0.1-3.2,1.1"></path>
			<path class="st5" d="M644.4,406.3c1.9,0.3,3.9,0.4,5.8,0.4"></path>
			<path class="st5" d="M652.8,414.9c-3,0.5-4.6,0.4-7.7,0.1"></path>
			<path class="st5" d="M658.4,365.8c-0.2,4.2,0.1,8.5,0.8,12.7c0,0.2,0.1,0.4,0.2,0.5"></path>
			<path class="st5" d="M658.8,365c2.4-0.3,4.8-0.2,7.1,0.1"></path>
			<path class="st5" d="M658.8,371.6c1.6,0.3,3.3,0.4,5,0.4"></path>
			<path class="st5" d="M666,379.1c-2.6,0.4-4,0.3-6.6,0"></path>
			<path class="st5" d="M675.7,365.3c-1.1-0.6-2.4-0.6-3.3,0.3c-0.9,0.8-1.1,2.2-0.6,3.2c0.5,0.8,1.2,1.3,2,1.6
				c0.8,0.3,1.7,0.6,2.5,0.9c1.1,0.4,2.1,1.2,2.6,2.3c0.4,0.9,0.2,2-0.2,2.9c-0.5,0.9-1.3,1.6-2.2,1.9c-1.9,0.8-4.3,0.1-5.4-1.7"></path>
		</g>
		</svg>
		`;

    paper.project.importSVG(svg, function(item) {
      item.scale(2);
      item.strokeWidth = 3;
      item.strokeColor = "#000000";
      item.applyMatrix = true;
      item.position = _this.state.currentEventPoint;
      item.pivot = _this.state.currentEventPoint;
      item.strokeCap = "round";
    });
  };

  activateTool() {
    if (paper.tool) paper.tool.remove();
    let tool = new Tool();
    let _this = this;

    tool.onMouseDown = event => {
      _this.setState({ currentEventPoint: event.downPoint });
      _this.renderSymbol();
    };

    tool.onMouseDrag = event => {};
    tool.onMouseUp = event => {};
    tool.activate();
  }

  render() {
    return (
      <div className="Text" style={styles.icon}>
        <a onClick={this.activateTool}>Symbol</a>
      </div>
    );
  }
}

export default SvgSymbol;
